import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UserCompanyActions } from "../actions";
import { UserCompanyService } from "../services";

@Injectable({
  providedIn: "root",
})
export class CompanyGuard implements CanActivate {
  constructor(
    private store: Store,
    private companyService: UserCompanyService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.companyService.get(next.params.company).pipe(
      catchError(() => of(null)),
      map((company) => {
        if (!company) {
          return this.router.parseUrl("/companies");
        }
        this.store.dispatch(
          UserCompanyActions.setCurrentCompany({ id: company.id })
        );
        return true;
      })
    );
  }
}
