import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { RideOrder } from "../../../models";

export const fetchCompanyRides = createAction(
  "[RideOrder/API] Fetch company ride orders",
  props<{ company: string }>()
);

export const loadCompanyRideOrders = createAction(
  "[RideOrder/API] Load RideOrders",
  props<{ rideOrders: RideOrder[] }>()
);

export const loadRideOrders = createAction(
  "[RideOrder/API] Load RideOrders",
  props<{ rideOrders: RideOrder[] }>()
);

export const addRideOrder = createAction(
  "[RideOrder/API] Add RideOrder",
  props<{ rideOrder: RideOrder }>()
);

export const upsertRideOrder = createAction(
  "[RideOrder/API] Upsert RideOrder",
  props<{ rideOrder: RideOrder }>()
);

export const addRideOrders = createAction(
  "[RideOrder/API] Add RideOrders",
  props<{ rideOrders: RideOrder[] }>()
);

export const upsertRideOrders = createAction(
  "[RideOrder/API] Upsert RideOrders",
  props<{ rideOrders: RideOrder[] }>()
);

export const updateRideOrder = createAction(
  "[RideOrder/API] Update RideOrder",
  props<{ rideOrder: Update<RideOrder> }>()
);

export const updateRideOrders = createAction(
  "[RideOrder/API] Update RideOrders",
  props<{ rideOrders: Update<RideOrder>[] }>()
);

export const deleteRideOrder = createAction(
  "[RideOrder/API] Delete RideOrder",
  props<{ id: string }>()
);

export const deleteRideOrders = createAction(
  "[RideOrder/API] Delete RideOrders",
  props<{ ids: string[] }>()
);

export const clearRideOrders = createAction("[RideOrder/API] Clear RideOrders");
