import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Platform } from '@ionic/angular';
import { from, fromEvent, Observable, of } from 'rxjs';
import { catchError, first, shareReplay, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class LocationPermissionService {
  deviceReady$ = fromEvent(document, 'deviceready').pipe(first(), shareReplay());

  constructor(
    private diagnostic: Diagnostic,
    private platform: Platform,
    private locationAccuracy: LocationAccuracy,
  ) { }

  requestPermissions() {
    return this.deviceReady$.pipe(
      switchMap(_ => from(this.diagnostic.requestLocationAuthorization())),
      catchError((error) => of(this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE)),
      first(),
    );
  }

  getPermissionStatus() {
    return this.deviceReady$.pipe(
      switchMap(_ => from(this.diagnostic.getLocationAuthorizationStatus())),
      catchError((error) => of(this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE)),
      first(),
    );
  }

  isLocationEnabled() {
    return this.deviceReady$.pipe(
      switchMap(_ => from(this.diagnostic.isLocationEnabled())),
      catchError((error) => of(true)),
      first(),
    );
  }

  enableLocation() {
    if (this.platform.is("ios")) {
      return of(true);
    }

    return this.deviceReady$.pipe(
      switchMap(_ => this.locationAccuracy.request(
        this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY
      )),
      catchError((error) => of(true)),
      first(),
    );
  }

}
