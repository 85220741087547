import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { UserCompany } from "../models/user-company.model";

export const setCurrentCompany = createAction(
  "[UserCompany/API] Set Current Company",
  props<{ id: string }>()
);

export const currentCompanyChanged = createAction(
  "[UserCompany/API] Current Company Changed",
  props<{ id: string }>()
);

export const loadUserCompanies = createAction(
  "[UserCompany/API] Load User Companies"
);

export const loadUserCompaniesSuccess = createAction(
  "[UserCompany/API] Load User Companies Success",
  props<{ companies: UserCompany[] }>()
);

export const loadUserCompaniesFailed = createAction(
  "[UserCompany/API] Load User Companies Failed",
  props<{ error: any }>()
);

export const addUserCompany = createAction(
  "[UserCompany/API] Add User Company",
  props<{ company: UserCompany }>()
);

export const upsertUserCompany = createAction(
  "[UserCompany/API] Upsert User Company",
  props<{ company: UserCompany }>()
);

export const addUserCompanies = createAction(
  "[UserCompany/API] Add User Companies",
  props<{ companies: UserCompany[] }>()
);

export const upsertUserCompanies = createAction(
  "[UserCompany/API] Upsert User Companies",
  props<{ companies: UserCompany[] }>()
);

export const updateUserCompany = createAction(
  "[UserCompany/API] Update User Company",
  props<{ company: Update<UserCompany> }>()
);

export const updateUserCompanies = createAction(
  "[UserCompany/API] Update User Companies",
  props<{ companies: Update<UserCompany>[] }>()
);

export const deleteUserCompany = createAction(
  "[UserCompany/API] Delete User Company",
  props<{ id: string }>()
);

export const deleteUserCompanies = createAction(
  "[UserCompany/API] Delete User Companies",
  props<{ ids: string[] }>()
);

export const clearUserCompanies = createAction(
  "[UserCompany/API] Clear User Companies"
);
export const reset = createAction("[UserCompany/API] Reset User Companies");
