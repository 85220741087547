import { Company } from "../../company";
import { RideOrderType } from "./ride-order-type";
import { RideRoute } from "./ride-route.model";
import { RideWaypoint } from "./ride-waypoint.model";

export enum RideOrderStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  EXPIRED = "expired",
  TAKEN = "taken",
}

export interface RideOrder {
  id: number;
  company_id: number;
  order_type: RideOrderType;
  space_type: string;
  max_detour_distance: number;
  departure_at: string;
  arrival_at: string;
  cargo_volume: number;
  vehicle_type: string;
  stock_type_id: string;
  stock_details: string;
  status: RideOrderStatus;
  price: number;
  waypoints: RideWaypoint[];
  route: RideRoute;
  company: Company;
}
