import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILatLng } from '@ionic-native/google-maps';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PlaceData } from '../types/place.type';

export interface LatLngBounds {
  lat: number;
  lng: number;
}

const BASE_GEOCODE_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

@Injectable({
  providedIn: 'root',
})
export class GeocodeService {
  private API_KEY;
  private addressTypeOrder = [
    'locality',
    'administrative_area_level_2',
    'administrative_area_level_1',
    'country',
  ];
  constructor(private http: HttpClient) {
    this.API_KEY = environment.googleApiKey;
  }
  getDefaultAddress(coords: ILatLng): PlaceData {
    return {
      types: [],
      geometry: {
        location: coords,
        bounds: {
          northeast: coords,
          southwest: coords,
        },
        location_type: 'APPROXIMATE',
        viewport: {
          northeast: coords,
          southwest: coords,
        },
      },
      access_points: [],
      place_id: '',
      address_components: [],
      formatted_address: `${coords.lat},${coords.lng}`,
    };
  }
  getAddress(coords: LatLngBounds): Observable<PlaceData> {
    return this.http
      .get(BASE_GEOCODE_URL, {
        params: {
          latlng: `${coords.lat},${coords.lng}`,
          key: this.API_KEY,
          result_type: 'political',
          language: 'en',
        },
      })
      .pipe(
        map((response: any) => {
          let bestAnswer = this.parseApiResults(response.results);
          if (!bestAnswer) {
            bestAnswer = this.getDefaultAddress(coords);
          }
          bestAnswer.geometry.location = coords;
          return bestAnswer;
        })
      );
  }
  private parseApiResults(results: Array<PlaceData>): PlaceData {
    for (const addressType of this.addressTypeOrder) {
      const place = results.find(
        (placeData) => placeData.types.indexOf(addressType) >= 0
      );
      if (place) {
        return place;
      }
    }
    return null;
  }
}
