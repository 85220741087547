import { AbstractControl, ValidatorFn } from "@angular/forms";
import * as phone from "phone";

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value === undefined || isNaN(control.value)) {
      return { phone: true };
    }
    const phoneNumber = control.value;
    if (!isPhoneValid(phoneNumber, "RO") && !isPhoneValid(phoneNumber)) {
      return { phone: true };
    }
    return null;
  };
}

function isPhoneValid(phoneNumber, country?) {
  return !!phone(phoneNumber, country).length;
}
