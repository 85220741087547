import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { CompanyUserActions } from "../../../../company";
import { UserCompanyActions } from "../../../../user-company";
import { RideActions } from "../actions";
import { RideService } from "../services";

@Injectable()
export class RideEffects {
  loadRides$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RideActions.loadRides),
      concatMap(({ company }) => this.rideService.fetchCompanyRides(company)),
      map((rides) => RideActions.loadRidesSuccess({ rides })),
      catchError((e) => of(RideActions.loadRidesFailed({ error: e })))
    );
  });

  companyChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserCompanyActions.currentCompanyChanged),
      concatMap(({ id }) => of(RideActions.loadRides({ company: id })))
    );
  });

  constructor(private actions$: Actions, private rideService: RideService) {}
}
