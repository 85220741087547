import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  switchMap,
} from "rxjs/operators";
import { AuthActions } from "../../authentication/actions";
import * as AccountActions from "../actions/account.actions";
import { AccountService } from "../services/account.service";

@Injectable()
export class AccountEffects {
  loadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadAccount),
      switchMap(() =>
        this.account.fetchData().pipe(
          map((data) => AccountActions.loadAccountSuccess({ user: data })),
          catchError((error) =>
            of(AccountActions.loadAccountFailure({ error }))
          )
        )
      )
    )
  );
  updateData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AccountActions.updateAccountData),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        exhaustMap((action) =>
          this.account.updateData(action.user).pipe(
            map((user) => {
              return AccountActions.updateAccountDataSuccess({ user });
            })
          )
        )
      );
    },
    { dispatch: false }
  );
  updateAvailability$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AccountActions.updateAvailabilityStatus),
        exhaustMap((action) => this.account.updateAvailability(action.status)),
        map((user) => AccountActions.updateAccountDataSuccess({ user }))
      );
    },
    { dispatch: true }
  );

  constructor(private actions$: Actions, private account: AccountService) {}
}
