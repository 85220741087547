import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VehicleTypeService } from "./services/vehicle-type.service";
import { StoreModule } from "@ngrx/store";
import { fromVehicleType } from "./reducers";
import { EffectsModule } from "@ngrx/effects";
import { VehicleTypeEffects } from "./effects/vehicle-type.effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromVehicleType.vehicleTypesFeatureKey,
      fromVehicleType.reducer
    ),
    EffectsModule.forFeature([VehicleTypeEffects]),
  ],
  providers: [VehicleTypeService],
})
export class VehicleModule {}
