import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import {
  RideOrder,
  RideOrderType,
  RideRoute,
  RideWaypoint,
} from "../../models";
import { SpaceTypes } from "../../models";
import { RideOrderData, RideRouteData } from "../../types";
export const loadRideSetup = createAction("[RideSetup] Load RideSetup");

export const initRideOrder = createAction(
  "[RideOrder/Setup] Init New Ride Order",
  props<{ orderType: RideOrderType; space: SpaceTypes }>()
);
export const initRideOrderOffer = createAction(
  "[RideOrder/Setup] Init Offer",
  props<{ space: SpaceTypes }>()
);
export const initRideOrderRequest = createAction(
  "[RideOrder/Setup] Init Request",
  props<{ space: SpaceTypes }>()
);

export const updateRideOrder = createAction(
  "[RideOrder/Setup] Update RideOrder",
  props<{ rideOrder: Partial<RideOrderData> }>()
);
export const updateRideRoute = createAction(
  "[RideOrder/Setup] Update RideRoute",
  props<{ rideRoute: Partial<RideOrderData> }>()
);

export const loadWaypoints = createAction(
  "[RideOrder/Setup] Load Waypoints",
  props<{ waypoints: RideWaypoint[] }>()
);
export const addWaypoint = createAction(
  "[RideOrder/Setup] Add Waypoint",
  props<{ waypoint: RideWaypoint }>()
);
export const removeWaypoint = createAction(
  "[RideOrder/Setup] Remove Waypoint",
  props<{ id: string }>()
);
export const updateWaypoint = createAction(
  "[RideOrder/Setup] Update Waypoint",
  props<{ update: Update<RideWaypoint> }>()
);

export const generateRouteSuccess = createAction(
  "[RideOrder/Setup] Generate Route Sucess",
  props<{ rideRoute: RideRouteData }>()
);

export const generateRouteFailed = createAction(
  "[RideOrder/Setup] Generate Route Failed",
  props<{ error: any }>()
);

export const generateRoute = createAction("[RideOrder/Setup] Generate Route");

export const clearWaypoints = createAction("[RideOrder/Setup] Clear Waypoints");
