import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { CompanyVehicle } from "../models/company-vehicle.model";
import * as CompanyVehicleActions from "../actions/company-vehicle.actions";

export const companyVehiclesFeatureKey = "companyVehicles";

export interface State extends EntityState<CompanyVehicle> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CompanyVehicle> = createEntityAdapter<CompanyVehicle>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(CompanyVehicleActions.addCompanyVehicle, (state, action) =>
    adapter.addOne(action.companyVehicle, state)
  ),
  on(CompanyVehicleActions.upsertCompanyVehicle, (state, action) =>
    adapter.upsertOne(action.companyVehicle, state)
  ),
  on(CompanyVehicleActions.addCompanyVehicles, (state, action) =>
    adapter.addMany(action.companyVehicles, state)
  ),
  on(CompanyVehicleActions.upsertCompanyVehicles, (state, action) =>
    adapter.upsertMany(action.companyVehicles, state)
  ),
  on(CompanyVehicleActions.updateCompanyVehicle, (state, action) =>
    adapter.updateOne(action.companyVehicle, state)
  ),
  on(CompanyVehicleActions.updateCompanyVehicles, (state, action) =>
    adapter.updateMany(action.companyVehicles, state)
  ),
  on(CompanyVehicleActions.deleteCompanyVehicle, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(CompanyVehicleActions.deleteCompanyVehicles, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(CompanyVehicleActions.clearCompanyVehicles, (state) =>
    adapter.removeAll(state)
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
