import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { VehicleType } from "../models/vehicle-type.model";
import * as VehicleTypeActions from "../actions/vehicle-type.actions";

export const vehicleTypesFeatureKey = "vehicleTypes";

export interface State extends EntityState<VehicleType> {
  // additional entities state properties
  loaded: boolean,
  error: any
}

export const adapter: EntityAdapter<VehicleType> = createEntityAdapter<VehicleType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  error: undefined,
});

export const reducer = createReducer(
  initialState,
  on(VehicleTypeActions.addVehicleType, (state, action) =>
    adapter.addOne(action.vehicleType, state)
  ),
  on(VehicleTypeActions.upsertVehicleType, (state, action) =>
    adapter.upsertOne(action.vehicleType, state)
  ),
  on(VehicleTypeActions.addVehicleTypes, (state, action) =>
    adapter.addMany(action.vehicleTypes, state)
  ),
  on(VehicleTypeActions.upsertVehicleTypes, (state, action) =>
    adapter.upsertMany(action.vehicleTypes, state)
  ),
  on(VehicleTypeActions.updateVehicleType, (state, action) =>
    adapter.updateOne(action.vehicleType, state)
  ),
  on(VehicleTypeActions.updateVehicleTypes, (state, action) =>
    adapter.updateMany(action.vehicleTypes, state)
  ),
  on(VehicleTypeActions.deleteVehicleType, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(VehicleTypeActions.deleteVehicleTypes, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(VehicleTypeActions.loadVehicleTypesSuccess, (state, action) =>
    adapter.upsertMany(action.vehicleTypes, { ...state, loaded: true })
  ),
  on(VehicleTypeActions.loadVehicleTypesFailed, (state, action) =>
    ({ ...state, error: action.error })
  ),
  on(VehicleTypeActions.clearVehicleTypes, (state) => adapter.removeAll({ ...state, loaded: false }))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
