import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { UserCompanyActions } from "../../../../user-company";
import { CompanyVehicleActions } from "../actions";
import { CompanyVehiclesService } from "../services";

@Injectable()
export class CompanyVehicleEffects {
  changeCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserCompanyActions.currentCompanyChanged),
      map(({ id }) =>
        CompanyVehicleActions.loadCompanyVehicles({ company: id })
      )
    );
  });

  loadCompanyUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyVehicleActions.loadCompanyVehicles),
      mergeMap(({ company }) =>
        this.companyVehiclesService.getAll(company).pipe(
          map((companyVehicles) =>
            CompanyVehicleActions.upsertCompanyVehicles({ companyVehicles })
          ),
          catchError((error) => of(null))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private companyVehiclesService: CompanyVehiclesService
  ) {}
}
