import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../../../services/api.service";
import { Company } from "../models";
import { CompanyDto } from "../types/company.dto";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(private api: ApiService) {}

  get(id: number): Observable<Company> {
    return this.api
      .get(`companies/${id}`)
      .pipe(map(({ company }) => company as Company));
  }
}
