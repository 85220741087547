import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { RideSearchEffects } from "./ride-search.effects";
import * as fromRideSearchResult from "./ride-search-result.reducer";
import { StoreModule } from "@ngrx/store";
import { RideSearchService } from "./ride-search.service";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([RideSearchEffects]),
    StoreModule.forFeature(
      fromRideSearchResult.rideSearchResultsFeatureKey,
      fromRideSearchResult.reducer
    ),
  ],
  providers: [RideSearchService],
})
export class RideSearchModule {}
