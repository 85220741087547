import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { CompanyInvite } from "../models/company-invite.model";
import * as CompanyInviteActions from "../actions/company-invite.actions";

export const companyInvitesFeatureKey = "companyInvites";

export interface State extends EntityState<CompanyInvite> {
  // additional entities state properties
  userInvitesLoaded: boolean;
  error: any;
}

export const adapter: EntityAdapter<CompanyInvite> = createEntityAdapter<CompanyInvite>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  userInvitesLoaded: false,
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(CompanyInviteActions.addCompanyInvite, (state, action) =>
    adapter.addOne(action.companyInvite, state)
  ),
  on(CompanyInviteActions.upsertCompanyInvite, (state, action) =>
    adapter.upsertOne(action.companyInvite, state)
  ),
  on(CompanyInviteActions.addCompanyInvites, (state, action) =>
    adapter.addMany(action.companyInvites, state)
  ),
  on(CompanyInviteActions.upsertCompanyInvites, (state, action) =>
    adapter.upsertMany(action.companyInvites, state)
  ),
  on(CompanyInviteActions.updateCompanyInvite, (state, action) =>
    adapter.updateOne(action.companyInvite, state)
  ),
  on(CompanyInviteActions.updateCompanyInvites, (state, action) =>
    adapter.updateMany(action.companyInvites, state)
  ),
  on(CompanyInviteActions.deleteCompanyInvite, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(CompanyInviteActions.deleteCompanyInvites, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(CompanyInviteActions.loadCompanyInviteSuccess, (state, action) =>
    adapter.setAll(action.companyInvites, state)
  ),
  on(CompanyInviteActions.loadUserCompanyInvitesSuccess, (state, action) =>
    adapter.addMany(action.companyInvites, {
      ...state,
      userInvitesLoaded: true,
    })
  ),
  on(CompanyInviteActions.loadUserCompanyInvitesFailed, (state, { error }) => ({
    ...state,
    error,
  })),
  on(CompanyInviteActions.clearCompanyInvites, (state) =>
    adapter.removeAll(state)
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
