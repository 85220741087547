import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlaceAutocompleteService } from "./services/place-autocomplete.service";
import { PlaceGeocoderService } from "./services/place-geocoder.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class GoogleModule {
  public static forRoot(): ModuleWithProviders<GoogleModule> {
    return {
      ngModule: GoogleModule,
      providers: [
        PlaceGeocoderService,
        PlaceAutocompleteService,
        PlaceGeocoderService,
      ],
    };
  }
}
