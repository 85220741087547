import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromUserCompany from "../reducers";

export const selectCompanyState = createFeatureSelector<fromUserCompany.State>(
  fromUserCompany.userCompaniesFeatureKey
);

export const selectCurrentId = createSelector(
  selectCompanyState,
  (state) => state.currentCompany
);
export const loaded = createSelector(
  selectCompanyState,
  (state) => state.loaded
);
export const loading = createSelector(
  selectCompanyState,
  (state) => !state.loaded
);
export const selectAll = createSelector(
  selectCompanyState,
  fromUserCompany.selectAll
);

export const selectEntities = createSelector(
  selectCompanyState,
  fromUserCompany.selectEntities
);

export const selectById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectTotal = createSelector(
  selectCompanyState,
  fromUserCompany.selectTotal
);

export const selectIds = createSelector(
  selectCompanyState,
  fromUserCompany.selectIds
);

export const selectCurrent = createSelector(
  selectCurrentId,
  selectEntities,
  (id, state) => state[id]
);
