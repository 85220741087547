import { createFeatureSelector, createSelector } from "@ngrx/store";
import { fromVehicleType } from "../reducers";

export const selectState = createFeatureSelector<fromVehicleType.State>(
  fromVehicleType.vehicleTypesFeatureKey
);

// export const selectLoaded = createSelector(
//   selectState,
//   (state: fromVehicleType.State) => state.loaded
// );
// export const selectLoading = createSelector(
//   selectState,
//   (state: fromVehicleType.State) => !state.loaded
// );

export const selectAll = createSelector(selectState, fromVehicleType.selectAll);
export const selectLoaded = createSelector(
  selectState,
  (state) => state.loaded
);
export const selectEntities = createSelector(
  selectState,
  fromVehicleType.selectEntities
);

export const selectTotal = createSelector(
  selectState,
  fromVehicleType.selectTotal
);

export const selectIds = createSelector(
  selectState,
  fromVehicleType.selectTotal
);
