import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, tap } from "rxjs/operators";
import { PushActions } from "../actions";
import { combineLatest } from "rxjs";
import { DeviceActions } from "../../device/actions";
import { PushService } from "../services/push.service";
import { AuthActions } from "tirup-api";

@Injectable()
export class PushEffects {
  load$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(AuthActions.authorized)),
      this.actions$.pipe(ofType(DeviceActions.deviceReady)),
    ]).pipe(
      map(() => {
        return PushActions.loadPush();
      })
    );
  });
  loadPush$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PushActions.loadPush),
        tap(() => this.notification.register())
      );
    },
    { dispatch: false }
  );
  pushRegistered$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PushActions.pushRegistered),
        tap(() => {
          this.notification.registerChannels(),
            this.notification.registerTopics()
        })
      );
    },
    { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    private notification: PushService,
    private store: Store
  ) { }
}
