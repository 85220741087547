import { Injectable } from "@angular/core";
import { ApiService } from "../../../services/api.service";

export interface DeviceData {
  uuid: string;
  model: string;
  manufacturer: string;
  version: string;
  serial: string;
  registration: string;
}

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  constructor(private api: ApiService) { }
  registerDevice(data: DeviceData) {
    return this.api.post("device/register", data);
  }
}
