import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import * as RideOrderActions from "../actions/ride-order.actions";
import { RideOrder } from "../../../models";

export const rideOrdersFeatureKey = "rideOrders";

export interface State extends EntityState<RideOrder> {
  // additional entities state properties
}

export const adapter: EntityAdapter<RideOrder> = createEntityAdapter<RideOrder>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(RideOrderActions.addRideOrder, (state, action) =>
    adapter.addOne(action.rideOrder, state)
  ),
  on(RideOrderActions.upsertRideOrder, (state, action) =>
    adapter.upsertOne(action.rideOrder, state)
  ),
  on(RideOrderActions.addRideOrders, (state, action) =>
    adapter.addMany(action.rideOrders, state)
  ),
  on(RideOrderActions.upsertRideOrders, (state, action) =>
    adapter.upsertMany(action.rideOrders, state)
  ),
  on(RideOrderActions.updateRideOrder, (state, action) =>
    adapter.updateOne(action.rideOrder, state)
  ),
  on(RideOrderActions.updateRideOrders, (state, action) =>
    adapter.updateMany(action.rideOrders, state)
  ),
  on(RideOrderActions.deleteRideOrder, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(RideOrderActions.deleteRideOrders, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(RideOrderActions.loadRideOrders, (state, action) =>
    adapter.setAll(action.rideOrders, state)
  ),
  on(RideOrderActions.clearRideOrders, (state) => adapter.removeAll(state))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
