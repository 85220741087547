import { createFeatureSelector, createSelector } from "@ngrx/store";

import { fromRideOrders } from "../reducers";

export const selectCompanyState = createFeatureSelector<fromRideOrders.State>(
  fromRideOrders.rideOrdersFeatureKey
);

export const selectAll = createSelector(
  selectCompanyState,
  fromRideOrders.selectAll
);

export const selectAllFromCompany = (id) =>
  createSelector(selectAll, (rides) =>
    rides.filter((ride) => ride.company_id == id)
  );

export const selectEntities = createSelector(
  selectCompanyState,
  fromRideOrders.selectEntities
);

export const selectById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectTotal = createSelector(
  selectCompanyState,
  fromRideOrders.selectTotal
);

export const selectIds = createSelector(
  selectCompanyState,
  fromRideOrders.selectIds
);
