import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { CompanyEvent } from "../models/company-event.model";
import * as CompanyEventActions from "../actions/company-event.actions";

export const companyEventsFeatureKey = "companyEvents";

export interface State extends EntityState<CompanyEvent> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CompanyEvent> = createEntityAdapter<CompanyEvent>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(CompanyEventActions.addCompanyEvent, (state, action) =>
    adapter.addOne(action.companyEvent, state)
  ),
  on(CompanyEventActions.upsertCompanyEvent, (state, action) =>
    adapter.upsertOne(action.companyEvent, state)
  ),
  on(CompanyEventActions.addCompanyEvents, (state, action) =>
    adapter.addMany(action.companyEvents, state)
  ),
  on(CompanyEventActions.upsertCompanyEvents, (state, action) =>
    adapter.upsertMany(action.companyEvents, state)
  ),
  on(CompanyEventActions.updateCompanyEvent, (state, action) =>
    adapter.updateOne(action.companyEvent, state)
  ),
  on(CompanyEventActions.updateCompanyEvents, (state, action) =>
    adapter.updateMany(action.companyEvents, state)
  ),
  on(CompanyEventActions.deleteCompanyEvent, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(CompanyEventActions.deleteCompanyEvents, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(CompanyEventActions.loadCompanyEvents, (state, action) =>
    adapter.setAll(action.companyEvents, state)
  ),
  on(CompanyEventActions.clearCompanyEvents, (state) =>
    adapter.removeAll(state)
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
