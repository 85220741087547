import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocationEffects } from './effects';
import { locationReducer } from './reducers/location.reducers';
import { BackgroundGeolocationService } from './services/background-geolocation.service';
import { DirectionsService } from './services/directions.service';
import { GeocodeService } from './services/geocode.service';


@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([LocationEffects]), StoreModule.forFeature('location', locationReducer)],
  providers: [BackgroundGeolocation, LocationAccuracy],
})
export class GeolocationModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: GeolocationModule,
      providers: [GeocodeService, DirectionsService, BackgroundGeolocationService, Diagnostic],
    };
  }
}
