import { Injectable } from "@angular/core";
import { ApiService } from "projects/tirup-api/src/lib/services";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Company } from "../../../models";
import { CompanySearchFiltersDto } from "../dto";

@Injectable({
  providedIn: "root",
})
export class CompanySearchService {
  constructor(private api: ApiService) {}

  search(filters: CompanySearchFiltersDto): Observable<Company[]> {
    return this.api.get("companies/search", filters).pipe(
      map((data) => {
        return data.companies;
      })
    );
  }

  getByUid(uid: string) {
    return this.search({ uid }).pipe(
      map((companies) => {
        if (companies.length === 0) {
          throw Error("No results");
        }
        return companies[0];
      })
    );
  }
}
