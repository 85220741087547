import { Injectable } from "@angular/core";
import { FirebaseX, IChannelOptions } from "@ionic-native/firebase-x/ngx";
import { Store } from "@ngrx/store";
import { fromEvent } from "rxjs";
import { TirupApiState } from "../../../reducers";
@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(
    public firebase: FirebaseX,
    private store: Store<TirupApiState>
  ) {}
  /**
   * @todo everything related to the api
   * make registration of device
   * get all notifications
   * get notification details
   */
}
