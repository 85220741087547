import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AccountService } from "../services/account.service";

@Injectable({
  providedIn: "root",
})
export class VerifiedGuard implements CanActivate {
  constructor(public user: AccountService, public router: Router) {}

  canActivate(route, state): Observable<boolean | UrlTree> {
    return this.user.data$.pipe(
      filter((user) => !!user),
      map((user) => {
        return true;
      })
    );
  }
}
