import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AccountEffects } from "./effects/account.effects";
import { VerifiedGuard } from "./guards/verified.guard";
import { accountFeatureKey, reducer } from "./reducers/account.reducer";
import { AccountService } from "./services/account.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([AccountEffects]),
    StoreModule.forFeature(accountFeatureKey, reducer),
  ],
  providers: [],
})
export class AccountModule {
  public static forRoot(): ModuleWithProviders<AccountModule> {
    return {
      ngModule: AccountModule,
      providers: [AccountService, VerifiedGuard],
    };
  }
}
