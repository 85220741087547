import { createFeatureSelector, createSelector } from "@ngrx/store";

import { fromCompanyInvite } from "../reducers";

export const selectCompanyState = createFeatureSelector<fromCompanyInvite.State>(
  fromCompanyInvite.companyInvitesFeatureKey
);

export const userInvitesLoaded = createSelector(
  selectCompanyState,
  (state) => state.userInvitesLoaded
);

export const userInvitesLoading = createSelector(
  selectCompanyState,
  (state) => !state.userInvitesLoaded
);

export const selectAll = createSelector(
  selectCompanyState,
  fromCompanyInvite.selectAll
);

export const selectEntities = createSelector(
  selectCompanyState,
  fromCompanyInvite.selectEntities
);
export const selectByCompany = (id: number) =>
  createSelector(selectAll, (entities) =>
    entities.filter(({ company_id }) => company_id === id)
  );
export const selectByUser = (id: number) =>
  createSelector(selectAll, (entities) =>
    entities.filter(({ user_id }) => user_id === id)
  );
export const selectById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectTotal = createSelector(
  selectCompanyState,
  fromCompanyInvite.selectTotal
);

export const selectIds = createSelector(
  selectCompanyState,
  fromCompanyInvite.selectIds
);
