import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { UserCompanyActions } from "../../../../user-company";
import { RideOrdersActions } from "../actions";
import { RideOrdersService } from "../services";

@Injectable()
export class RideOrdersEffects {
  fetchCompanyRideOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RideOrdersActions.fetchCompanyRides),
      mergeMap(({ company }) =>
        this.rideOrderSerice.fetchCompanyRides(company).pipe(
          map((rideOrders) =>
            RideOrdersActions.upsertRideOrders({ rideOrders })
          ),
          catchError((error) => {
            return EMPTY;
          })
        )
      )
    );
  });
  companyChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserCompanyActions.currentCompanyChanged),
      map(({ id }) => RideOrdersActions.fetchCompanyRides({ company: id }))
    );
  });
  constructor(
    private actions$: Actions,
    private rideOrderSerice: RideOrdersService
  ) {}
}
