import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { ActiveRideEffects } from "./active-ride.effects";
import { StoreModule } from "@ngrx/store";
import { reducer, activeRideFeatureKey } from "./active-ride.reducer";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ActiveRideEffects]),
    StoreModule.forFeature(activeRideFeatureKey, reducer),
  ],
})
export class ActiveRideModule {}
