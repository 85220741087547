import { Injectable } from "@angular/core";
import { GeocoderRequest } from "@ionic-native/google-maps";
import { ActionSheetController, ModalController } from "@ionic/angular";
import { ModalOptions } from "@ionic/core";
import { from, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DirectionsService {
  directions = new google.maps.DirectionsService();
  constructor(
    private actionSheet: ActionSheetController,
    private modalController: ModalController
  ) {}

  private route(
    request: google.maps.DirectionsRequest
  ): Promise<google.maps.DirectionsResult> {
    return new Promise((resolve, reject) =>
      this.directions.route(request, (result, status) => {
        if (status !== google.maps.DirectionsStatus.OK) {
          console.error({ result, status });
          return reject(new Error("Failed to fetch directions"));
        }
        return resolve(result);
      })
    );
  }

  getSimpleRoute(
    request: google.maps.DirectionsRequest
  ): Observable<google.maps.DirectionsRoute> {
    return from(this.route(request)).pipe(map((data) => data.routes[0]));
  }
}
