import { createAction, props } from "@ngrx/store";

export const loadPush = createAction("[Push] Load Push");

export const pushRegistered = createAction(
  "[Push] Registered",
  props<{ token: string }>()
);

export const pushNotificationReceived = createAction(
  "[Push] Notification received",
  props<{ notification: any }>()
);
export const pushNotificationTapped = createAction(
  "[Push] Notification tapped",
  props<{ notification: any }>()
);

export const activateRideNotifications = createAction(
  "[Push] Activate New Ride Notifications"
);
export const deactivateRideNotifications = createAction(
  "[Push] Deactivate New Ride Notifications"
);
