import { createAction, props } from "@ngrx/store";
export const deviceReady = createAction("[Device] Device Ready");
export const loadDevice = createAction("[Device] Load Devices");

export const loadDeviceSuccess = createAction(
  "[Device] Load Devices Success",
  props<{ data: any }>()
);

export const loadDeviceFailure = createAction(
  "[Device] Load Devices Failure",
  props<{ error: any }>()
);
