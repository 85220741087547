import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, concatMap, map, mergeMap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as RideSetupActions from "./ride-setup.actions";
import { RideSetupService } from "./ride-setup.service";
import { RideRouteData } from "../../types";

@Injectable()
export class RideSetupEffects {
  generateRoute$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RideSetupActions.generateRoute),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      mergeMap(() =>
        this.rideSetup.generateRoute().pipe(
          map((rideRoute) =>
            RideSetupActions.generateRouteSuccess({ rideRoute })
          ),
          catchError((error) =>
            of(RideSetupActions.generateRouteFailed({ error }))
          )
        )
      )
    );
  });

  constructor(private actions$: Actions, private rideSetup: RideSetupService) {}
}
