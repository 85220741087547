import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { CompanyVehicleEffects } from "./effects/company-vehicle.effects";
import { fromCompanyVehicle } from "./reducers";
import { CompanyVehiclesService } from "./services";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCompanyVehicle.companyVehiclesFeatureKey, fromCompanyVehicle.reducer),
    EffectsModule.forFeature([CompanyVehicleEffects]),
  ],
})
export class CompanyVehicleModule {
  public static forRoot(): ModuleWithProviders<CompanyVehicleModule> {
    return {
      ngModule: CompanyVehicleModule,
      providers: [CompanyVehiclesService],
    };
  }
}
