import { Injectable } from "@angular/core";
import { from } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlaceGeocoderService {
  geocoderService = new google.maps.Geocoder();
  constructor() {}
  private geocode(
    query: google.maps.GeocoderRequest
  ): Promise<google.maps.GeocoderResult> {
    return new Promise((resolve, reject) => {
      this.geocoderService.geocode(query, (res, err) => {
        if (err !== "OK") {
          reject(err);
        }
        if (res.length === 0) {
          reject(new Error("no results"));
        }
        resolve(res[0]);
      });
    });
  }
  getGeocodeByPlaceId(placeId: string) {
    return from(this.geocode({ placeId }));
  }
  getGeocodeByLatLng(lat: number, lng: number) {
    return from(this.geocode({ location: { lat, lng } }));
  }
}
