import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { PushEffects } from './effects/push.effects';
import { StoreModule } from '@ngrx/store';
import { fromPush } from './reducers';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { PushService } from './services/push.service';



@NgModule({
  declarations: [],
  imports: [
    EffectsModule.forFeature([PushEffects]),
    StoreModule.forFeature(fromPush.pushFeatureKey, fromPush.reducer),
    CommonModule
  ],
  providers: [FirebaseX, PushService]
})
export class PushModule { }
