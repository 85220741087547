import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { CompanyVehicle } from "../models/company-vehicle.model";

export const loadCompanyVehicles = createAction(
  "[CompanyVehicle/API] Load CompanyVehicles",
  props<{ company: string }>()
);

export const addCompanyVehicle = createAction(
  "[CompanyVehicle/API] Add CompanyVehicle",
  props<{ companyVehicle: CompanyVehicle }>()
);

export const upsertCompanyVehicle = createAction(
  "[CompanyVehicle/API] Upsert CompanyVehicle",
  props<{ companyVehicle: CompanyVehicle }>()
);

export const addCompanyVehicles = createAction(
  "[CompanyVehicle/API] Add CompanyVehicles",
  props<{ companyVehicles: CompanyVehicle[] }>()
);

export const upsertCompanyVehicles = createAction(
  "[CompanyVehicle/API] Upsert CompanyVehicles",
  props<{ companyVehicles: CompanyVehicle[] }>()
);

export const updateCompanyVehicle = createAction(
  "[CompanyVehicle/API] Update CompanyVehicle",
  props<{ companyVehicle: Update<CompanyVehicle> }>()
);

export const updateCompanyVehicles = createAction(
  "[CompanyVehicle/API] Update CompanyVehicles",
  props<{ companyVehicles: Update<CompanyVehicle>[] }>()
);

export const deleteCompanyVehicle = createAction(
  "[CompanyVehicle/API] Delete CompanyVehicle",
  props<{ id: string }>()
);

export const deleteCompanyVehicles = createAction(
  "[CompanyVehicle/API] Delete CompanyVehicles",
  props<{ ids: string[] }>()
);

export const clearCompanyVehicles = createAction(
  "[CompanyVehicle/API] Clear CompanyVehicles"
);
