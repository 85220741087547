import { Injectable } from '@angular/core';
import { ILatLng, ILatLngBounds } from '@ionic-native/google-maps';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import * as mapbox from '@mapbox/polyline';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const BASE_MAPS_DIRECTIONS = 'https://maps.googleapis.com/maps/api/directions/json';

export interface Step {
  distance: {
    text: string;
    value: number;
  };
  duration: {
    text: string;
    value: number;
  };
  end_address: string;
  end_location: ILatLngBounds;
  start_address: string;
  start_location: ILatLngBounds;
}

export interface Leg {
  distance: {
    text: string;
    value: number;
  };
  duration: {
    text: string;
    value: number;
  };
  duration_in_traffic: {
    text: string;
    value: number;
  };
  end_address: string;
  end_location: ILatLngBounds;
  start_address: string;
  start_location: ILatLngBounds;
  steps: Step[];
}

export interface DirectionsRoute {
  bounds: ILatLngBounds;
  legs: Leg[];
  overview_polyline: {
    points: string;
  };
}

export interface DirectionsResponse {
  routes: DirectionsRoute[];
  geocoded_waypoints: any;
}

@Injectable({
  providedIn: 'root',
})
export class DirectionsService {
  private API_KEY;

  constructor(private http: HTTP) {
    this.API_KEY = environment.googleApiKey;
  }

  directions(origin: ILatLng, destination: ILatLng): Observable<DirectionsResponse> {
    const options = {
      headers: {},
      params: {
        key: this.API_KEY,
        origin: `${origin.lat.toFixed(6)},${origin.lng.toFixed(6)}`,
        destination: `${destination.lat.toFixed(6)},${destination.lng.toFixed(6)}`,
        traffic_model: 'best_guess',
        departure_time: 'now',
        mode: 'driving',
        // types: 'locality'
      } as any,
    };

    return from(this.http.get(BASE_MAPS_DIRECTIONS, options.params, options.headers)).pipe(
      map((response: HTTPResponse) => {
        const responseData = JSON.parse(response.data);
        return responseData;
      }),
      catchError((er) => {
        console.error(er);
        throw { error: 'Unknown error' };
      })
    );
  }

  getRoutePoints(route: DirectionsRoute) {
    const points = mapbox.decode(route.overview_polyline.points);
    return points.map((point) => {
      return {
        lat: point[0],
        lng: point[1],
      };
    });
  }
}
