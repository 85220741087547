import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RoutingService } from "./services/routing.service";
import { FlexiblePolylineService } from "./services/flexible-polyline.service";

@NgModule({
  imports: [CommonModule],
  providers: [RoutingService, FlexiblePolylineService],
})
export class HereModule {}
