import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { VehicleType } from "../models/vehicle-type.model";

export const loadVehicleTypes = createAction(
  "[VehicleType/API] Load VehicleTypes"
);

export const loadVehicleTypesSuccess = createAction(
  "[VehicleType/API] Load VehicleTypes Success",
  props<{ vehicleTypes: VehicleType[] }>()
);

export const loadVehicleTypesFailed = createAction(
  "[VehicleType/API] Load VehicleTypes Failed",
  props<{ error: any }>()
);

export const addVehicleType = createAction(
  "[VehicleType/API] Add VehicleType",
  props<{ vehicleType: VehicleType }>()
);

export const upsertVehicleType = createAction(
  "[VehicleType/API] Upsert VehicleType",
  props<{ vehicleType: VehicleType }>()
);

export const addVehicleTypes = createAction(
  "[VehicleType/API] Add VehicleTypes",
  props<{ vehicleTypes: VehicleType[] }>()
);

export const upsertVehicleTypes = createAction(
  "[VehicleType/API] Upsert VehicleTypes",
  props<{ vehicleTypes: VehicleType[] }>()
);

export const updateVehicleType = createAction(
  "[VehicleType/API] Update VehicleType",
  props<{ vehicleType: Update<VehicleType> }>()
);

export const updateVehicleTypes = createAction(
  "[VehicleType/API] Update VehicleTypes",
  props<{ vehicleTypes: Update<VehicleType>[] }>()
);

export const deleteVehicleType = createAction(
  "[VehicleType/API] Delete VehicleType",
  props<{ id: string }>()
);

export const deleteVehicleTypes = createAction(
  "[VehicleType/API] Delete VehicleTypes",
  props<{ ids: string[] }>()
);

export const clearVehicleTypes = createAction(
  "[VehicleType/API] Clear VehicleTypes"
);
