import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { DeviceService } from "./services/device.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class DeviceModule {
  public static forRoot(): ModuleWithProviders<DeviceModule> {
    return {
      ngModule: DeviceModule,
      providers: [DeviceService],
    };
  }
}
