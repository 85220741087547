import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StockTypeEffects } from "./stock-type.effects";
import { StoreModule } from "@ngrx/store";
import { reducer, stockTypeFeatureKey } from "./stock-type.reducer";
import { StockTypeService } from "./stock-type.service";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(stockTypeFeatureKey, reducer),
    EffectsModule.forFeature([StockTypeEffects]),
  ],
  providers: [StockTypeService],
})
export class StockTypesModule {}
