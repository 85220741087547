import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../services";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CompanyPermission } from "../models";
import { CompanyUser } from "../../company-user";

@Injectable({
  providedIn: "root",
})
export class CompanyPermissionService {
  constructor(private api: ApiService) {}

  getAll(): Observable<CompanyPermission[]> {
    return this.api
      .get("company-permissions")
      .pipe(
        map(
          ({ company_permissions }) =>
            company_permissions as CompanyPermission[]
        )
      );
  }

  roleHasPermission() {}
}
