import { RideOrder } from "./ride-order.model";

export enum RideOrderCallStatus {
  APPROVED = "approved",
  REJECTED = "rejected",
  PENDING = "pending"
}

export interface RideOrderCall {
  id: number;
  status: RideOrderCallStatus;
  sender_company_id: number;
  target_company_id: number;
  sender_ride_order_id: number;
  target_ride_order_id: number;
  sender_ride_order?: RideOrder;
  target_ride_order?: RideOrder;
}
