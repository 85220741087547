import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { CompanyUser } from "../models/company-user.model";
import * as CompanyUserActions from "../actions/company-user.actions";

export const companyUsersFeatureKey = "companyUsers";

export interface State extends EntityState<CompanyUser> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CompanyUser> = createEntityAdapter<CompanyUser>(
  {}
);

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(CompanyUserActions.addCompanyUser, (state, action) =>
    adapter.addOne(action.companyUser, state)
  ),
  on(CompanyUserActions.upsertCompanyUser, (state, action) =>
    adapter.upsertOne(action.companyUser, state)
  ),
  on(CompanyUserActions.addCompanyUsers, (state, action) =>
    adapter.addMany(action.companyUsers, state)
  ),
  on(CompanyUserActions.upsertCompanyUsers, (state, action) =>
    adapter.upsertMany(action.companyUsers, state)
  ),
  on(CompanyUserActions.updateCompanyUser, (state, action) =>
    adapter.updateOne(action.companyUser, state)
  ),
  on(CompanyUserActions.updateCompanyUsers, (state, action) =>
    adapter.updateMany(action.companyUsers, state)
  ),
  on(CompanyUserActions.deleteCompanyUser, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(CompanyUserActions.deleteCompanyUsers, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  on(CompanyUserActions.clearCompanyUsers, (state) => adapter.removeAll(state))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
