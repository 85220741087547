import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { RideSetupEffects } from "./ride-setup.effects";
import * as fromRideSetup from "./ride-setup.reducer";
import { StoreModule } from "@ngrx/store";
import { HereModule } from "../../../@here";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HereModule,
    EffectsModule.forFeature([RideSetupEffects]),
    StoreModule.forFeature(
      fromRideSetup.rideSetupFeatureKey,
      fromRideSetup.reducer
    ),
  ],
})
export class RideSetupModule {}
