// NGRX
import { createAction, props } from '@ngrx/store';
import { LocationData } from '../types';

export const update = createAction(
  '[Location Update]',
  props<{ location: LocationData }>()
);
export const request = createAction('[Location Request]');
export const watch = createAction('[Location Watch]');

export const checkGPS = createAction('[Location GPS] GPS Check');
export const requestGPS = createAction('[Location GPS] GPS Request');
export const requestGpsFailed = createAction(
  '[Location GPS] GPS Request Failed'
);
export const requestGpsSuccess = createAction(
  '[Location GPS] GPS Request Success'
);

export const permissionCheck = createAction(
  '[Location Permission] Check Permission'
);
export const requestPermissions = createAction(
  '[Location Permission] Permission Request'
);
export const permissionGranted = createAction(
  '[Location Permission] Permission Granted'
);
export const permissionFailed = createAction(
  '[Location Permission] Permission Denied'
);
export const getCurrentLocation = createAction(
  '[Location] Get Current Location '
);
