import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ApiService } from "../../../../../services";
import { CompanyRole } from "../models/company-role.model";

@Injectable({
  providedIn: "root",
})
export class CompanyRoleService {
  roles$: Observable<CompanyRole[]>;

  constructor(private api: ApiService) { }

  get(id: number): Observable<CompanyRole> {
    return this.getAll().pipe(map(data => data.find((data) => id == data.id)));
  }

  getAll(): Observable<CompanyRole[]> {
    if (!this.roles$) {
      this.roles$ = this.api
        .get("company-roles")
        .pipe(map(({ companyRoles }) => companyRoles as CompanyRole[]), shareReplay());
    }
    return this.roles$;
  }
}
