export const environment = {
  googleApiKey: "AIzaSyBjYQVGFsXpW8YtX_gfRpXxHMK8zcCdIB4",
  apiURL: "https://tir-up.com",
  here: {
    javascript: {
      appId: "MoygObPPiYLF8DWnw9ng",
      secret: "rOxEryw1Y_lPDMCDJ1QRwv38Lt2aQfSdu5JZBf7oBdI",
    },
  },
  authTokenKey: "auth",
  nativeHTTP: false,
  production: true,
};
