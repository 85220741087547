import { RideOrder, RideRoute, RideWaypoint } from "../../../models";
import { RideEvent } from "./ride-event.model";

export class Ride {
  id: number;
  company_id: number;
  status: string;
  request_ride_order_id: number;
  offer_ride_order_id: number;
  request_compnay_id: number;
  offer_company_id: number;
  departure_at: Date;
  request_ride_order: RideOrder;
  offer_ride_order: RideOrder;
  route: RideRoute;
  waypoints: RideWaypoint[];
  events: RideEvent[];
}
