import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  CompanyPermissionService,
  CompanyRolePermissionsService,
  CompanyRoleService,
} from "./services";
import { CanEditUserGuard } from "./guards/can-edit-user.guard";

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class CompanyAclModule {
  public static forRoot(): ModuleWithProviders<CompanyAclModule> {
    return {
      ngModule: CompanyAclModule,
      providers: [
        CompanyRoleService,
        CompanyPermissionService,
        CompanyRolePermissionsService,
        CanEditUserGuard,
      ],
    };
  }
}
