import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromStockType from "./stock-type.reducer";

export const selectStockTypeState = createFeatureSelector<fromStockType.State>(
  fromStockType.stockTypeFeatureKey
);

export const selectStockTypesLoaded = createSelector(
  selectStockTypeState,
  (state: fromStockType.State) => state.loaded
);
export const selectStockTypesLoading = createSelector(
  selectStockTypeState,
  (state: fromStockType.State) => !state.loaded
);

export const selectAllStockTypes = createSelector(
  selectStockTypeState,
  fromStockType.selectAll
);

export const selectStockTypesEntities = createSelector(
  selectStockTypeState,
  fromStockType.selectEntities
);

export const selectStockTypesTotal = createSelector(
  selectStockTypeState,
  fromStockType.selectTotal
);

export const selectStockTypesIds = createSelector(
  selectStockTypeState,
  fromStockType.selectTotal
);
