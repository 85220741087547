import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RideService } from "./services/ride.service";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { fromRide } from "./reducers";
import { RideEffects } from "./effects";

@NgModule({
  declarations: [],
  providers: [RideService],
  imports: [
    CommonModule,
    EffectsModule.forFeature([RideEffects]),
    StoreModule.forFeature(fromRide.ridesFeatureKey, fromRide.reducer),
  ],
})
export class RideModule {}
