import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RideOrderCallService } from './services/ride-order-call.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    RideOrderCallService,
  ]
})
export class RideOrderCallModule { }
