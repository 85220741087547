import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { NotificationsService } from "./services/notifications.service";
@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [],
})
export class NotificationsModule {
  public static forRoot(): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [NotificationsService],
    };
  }
}
