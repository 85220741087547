const cuiValidator = function (cui) {
  let cif = cui;
  // Daca este string, elimina atributul fiscal si spatiile
  if (isNaN(parseFloat(cui)) || !isFinite(cui)) {
    cif = cif.toUpperCase();
    if (cif.indexOf("RO") === 0) {
      cif = cif.replace("RO", "");
    } else {
      return false;
    }
    cif = parseInt(cif.trim());
  } else {
    cif = parseInt(cif);
  }
  // daca are mai mult de 10 cifre sau mai putin de 6, nu-i valid
  if (cif.toString().length > 10 || cif.toString().length < 6) {
    return false;
  }
  // numarul de control
  let v = 753217532;

  // extrage cifra de control
  let c1 = parseInt(`${cif % 10}`);
  cif = parseInt(`${cif / 10}`);

  // executa operatiile pe cifre
  var t = 0;
  while (cif > 0) {
    t += (cif % 10) * (v % 10);
    cif = parseInt(`${cif / 10}`);
    v = parseInt(`${v / 10}`);
  }

  // aplica inmultirea cu 10 si afla modulo 11
  var c2 = parseInt(`${(t * 10) % 11}`);
  // daca modulo 11 este 10, atunci cifra de control este 0
  if (c2 == 10) {
    c2 = 0;
  }

  return c1 === c2;
};

export { cuiValidator };
