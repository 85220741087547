import { RideOrder, RideRoute, RideWaypoint } from "../../../models";
import { RideEventData, RideEventTypes } from "./ride-event.interface";

export class RideEvent {
  id: number;
  ride_id: number;
  author_id: number;
  created_at: number;
  type: string;
  details: string;
}
