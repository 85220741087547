import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { first, tap, map } from "rxjs/operators";
import { TirupApiState } from "../../../reducers";
import { ApiService } from "../../../services";
import { AuthActions } from "../actions";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private api: ApiService, private store: Store<TirupApiState>) {}

  public phoneAuthStepOne(phone: string) {
    return this.api.post("auth/phone/1", { phone }).pipe(
      map((data) => {
        return data;
      }),
      tap((res) => {})
    );
  }

  public phoneAuthStepTwo(code: string) {
    return this.api.post("auth/phone/2", { code }).pipe(
      map((data) => {
        return data;
      }),
      tap((res) => {
        this.store.dispatch(AuthActions.authorized({ token: res.token }));
      })
    );
  }
}
