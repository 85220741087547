import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CompanyIdentifierService, CompanyService } from "./services";
import { CompanyVehicleModule } from "./@modules/company-vehicle";
import { CompanyAclModule } from "./@modules/company-acl";
import { CompanyUserModule } from "./@modules/company-user";
import { CompanyInviteModule } from "./@modules/company-invite";
import { CompanySearchModule } from "./@modules/company-search";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CompanyVehicleModule,
    CompanyUserModule,
    CompanyAclModule,
    CompanyInviteModule,
    CompanySearchModule,
  ],
  exports: [
    CompanyVehicleModule,
    CompanyUserModule,
    CompanyAclModule,
    CompanyInviteModule,
  ],
})
export class CompanyModule {
  public static forRoot(): ModuleWithProviders<CompanyModule> {
    return {
      ngModule: CommonModule,

      providers: [CompanyService, CompanyIdentifierService],
    };
  }
}
