import { createReducer, on } from '@ngrx/store';
import * as DeviceActions from '../actions/device.actions';

export const deviceFeatureKey = 'device';

export interface State {}

export const initialState: State = {};

const deviceReducer = createReducer(
  initialState,
  on(DeviceActions.loadDevice, (state) => state),
  on(DeviceActions.loadDeviceSuccess, (state, action) => state),
  on(DeviceActions.loadDeviceFailure, (state, action) => state)
);
export function reducer(state: State | undefined, action) {
  return deviceReducer(state, action);
}
