import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CompanyModule } from "../company";
import { EffectsModule } from "@ngrx/effects";
import { UserCompanyEffects } from "./effects";
import { StoreModule } from "@ngrx/store";
import { reducer, userCompaniesFeatureKey } from "./reducers";
import { UserCompanyService } from "./services/user-company.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(userCompaniesFeatureKey, reducer),
    EffectsModule.forFeature([UserCompanyEffects]),
  ],
})
export class UserCompanyModule {
  public static forRoot(): ModuleWithProviders<UserCompanyModule> {
    return {
      ngModule: UserCompanyModule,
      providers: [UserCompanyService],
    };
  }
}
