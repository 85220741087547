import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromActiveRide from "../active-ride.reducer";

export const selectActiveRideState = createFeatureSelector<fromActiveRide.State>(
  fromActiveRide.activeRideFeatureKey
);

export const ride = createSelector(
  selectActiveRideState,
  (state) => state.ride
);
