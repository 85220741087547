import { createFeatureSelector, createSelector } from "@ngrx/store";

import { fromRide } from "../reducers";

export const selectCompanyState = createFeatureSelector<fromRide.State>(
  fromRide.ridesFeatureKey
);

export const selectAll = createSelector(selectCompanyState, fromRide.selectAll);

export const selectAllFromCompany = (id) =>
  createSelector(selectAll, (rides) =>
    rides.filter((ride) => ride.company_id == id)
  );

export const selectEntities = createSelector(
  selectCompanyState,
  fromRide.selectEntities
);

export const selectById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectTotal = createSelector(
  selectCompanyState,
  fromRide.selectTotal
);

export const selectIds = createSelector(selectCompanyState, fromRide.selectIds);
