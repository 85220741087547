import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CompanyGuard, AuthGuard } from "tirup-api";
import { AccountSetupGuard } from "./@core/guards/account-setup.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "companies",
    pathMatch: "full",
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./auth/auth.module").then((m) => m.AuthPageModule),
  },
  {
    path: "account-setup",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./views/account-setup/account-setup.module").then(
        (m) => m.AccountSetupModule
      ),
  },
  {
    path: "",
    canActivate: [AuthGuard, AccountSetupGuard],
    children: [
      {
        path: "company/view",
        loadChildren: () =>
          import("./views/company-view/company-view.module").then(
            (m) => m.CompanyViewPageModule
          ),
      },
      {
        path: "company/search",
        loadChildren: () =>
          import("./views/company-search/company-search.module").then(
            (m) => m.CompanySearchPageModule
          ),
      },
      {
        path: "companies",
        loadChildren: () =>
          import("./companies/companies.module").then((m) => m.CompaniesModule),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./views/settings/settings.module").then(
            (m) => m.SettingsPageModule
          ),
      },

      {
        path: "companies/invites",
        loadChildren: () =>
          import("./views/user/company-invites/company-invites.module").then(
            (m) => m.CompanyInvitesPageModule
          ),
      },
    ],
  },
  {
    path: "c/:company",
    canActivate: [AuthGuard, CompanyGuard, AccountSetupGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomePageModule),
      },

      {
        path: "ride-setup",
        loadChildren: () =>
          import("./ride-setup/ride-setup.module").then(
            (m) => m.RideSetupPageModule
          ),
      },
      {
        path: "ride-order/search",
        loadChildren: () =>
          import("./ride-search/ride-search.module").then(
            (m) => m.RideSearchModule
          ),
      },
      {
        path: "transactions",
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (m) => m.TransactionsPageModule
          ),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./notifications/notifications.module").then(
            (m) => m.NotificationsPageModule
          ),
      },
      {
        path: "rides",
        loadChildren: () =>
          import("./rides/rides.module").then((m) => m.RidesPageModule),
      },
      {
        path: "active-ride",
        loadChildren: () =>
          import("./active-ride/active-ride.module").then(
            (m) => m.ActiveRidePageModule
          ),
      },
      {
        path: "upcoming-rides",
        loadChildren: () =>
          import("./views/upcoming-rides/upcoming-rides.module").then(
            (m) => m.UpcomingRidesPageModule
          ),
      },
      {
        path: "ride-orders",
        loadChildren: () =>
          import("./ride-orders/ride-orders.module").then(
            (m) => m.RideOrdersPageModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./company/company.module").then((m) => m.CompanyModule),
      },
    ],
  },
  {
    path: "account-setup",
    loadChildren: () =>
      import("./views/account-setup/account-setup.module").then(
        (m) => m.AccountSetupModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
