export enum RideEventTypes {
  STARTED = "ride_started",
  REACHED_DEPARTURE_LOCATION = "reached_departure_location",
  REACHED_ARRIVAL_LOCATION = "reached_arrival_location",
  CARGO_LOADED = "cargo_loaded",
  FINISHED = "ride_finished",
}

export interface RideEventData {
  type: RideEventTypes;
  details?: string;
}
