import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of } from "rxjs";
import { first, map, switchMap, take } from "rxjs/operators";
import * as RideSetupSelectors from "./ride-setup.selectors";
import { ApiService } from "../../../../services";
import {
  RideOrder,
  RideOrderType,
  RideWaypointType,
  SpaceTypes,
} from "../../models";
import { RideOrderData, RideRouteData } from "../../types";
import { rideSetupTypes } from "./ride-setups.types";
import { DirectionsService } from "../../../google";
import { HereDirectionsInput, RoutingService } from "../../../@here";

@Injectable({
  providedIn: "root",
})
export class RideSetupService {
  constructor(
    private api: ApiService,
    private store: Store,
    private directionsService: RoutingService
  ) {}

  getSetupSteps(type: string, space: SpaceTypes) {
    const data = rideSetupTypes[type][space];
    if (!data) {
      throw new Error("This kind of setup is not found");
    }
    return data.steps;
  }
  submitCurrentRide(): Observable<RideOrder> {
    return combineLatest([
      this.store.select(RideSetupSelectors.selectRideOrderData),
      this.store.select(RideSetupSelectors.selectRouteData),
      this.store.select(RideSetupSelectors.selectAllWaypoints),
    ]).pipe(
      take(1),
      map(([rideOrder, routeData, waypoints]) => {
        return {
          ...rideOrder,
          route: routeData,
          waypoints,
        } as RideOrderData;
      }),
      switchMap((ride) => {
        if (ride.route) {
          return of(ride);
        }
        const end = ride.waypoints.find(
          (data) => data.type === RideWaypointType.DESTINATION
        );
        if (
          ride.space_type === SpaceTypes.FULL &&
          ride.order_type === RideOrderType.OFFER &&
          !end
        ) {
          return of(ride);
        }

        return this.generateRoute().pipe(
          map((route) => ({ ...ride, route } as RideOrderData))
        );
      }),
      switchMap((data) => {
        return this.submitRide(data);
      })
    );
  }

  generateRoute() {
    return this.store.select(RideSetupSelectors.selectAllWaypoints).pipe(
      switchMap((waypoints) => {
        const origin = waypoints.find(
          (data) => data.type === RideWaypointType.ORIGIN
        );
        const destination = waypoints.find(
          (data) => data.type === RideWaypointType.DESTINATION
        );
        const via = waypoints
          .filter((data) => data.type === RideWaypointType.WAYPOINT)
          .map((data) => ({ lat: data.location_lat, lng: data.location_lng }));

        const request: HereDirectionsInput = {
          transportMode: "truck",
          origin: { lat: origin.location_lat, lng: origin.location_lng },
          destination: {
            lat: destination.location_lat,
            lng: destination.location_lng,
          },
          via,
        };
        return this.directionsService.routeSummary(request).pipe(
          map((result) => {
            const rideRoute: RideRouteData = {
              distance: result.distance,
              duration: result.duration,
              overview_polyline: result.polyline,
            };
            return rideRoute;
          })
        );
      })
    );
  }
  submitRide(data: RideOrderData): Observable<RideOrder> {
    return this.api
      .post(`companies/${data.company_id}/ride-orders`, data)
      .pipe(map(({ ride_order }) => ride_order));
  }
}
