import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApiService } from "projects/tirup-api/src/lib/services";
import { map, tap } from "rxjs/operators";
import { RideOrder, RideOrderType } from "../../../models";
import { Ride, RideEvent, RideEventData } from "../models";
import { RideSelectors } from "../selectors";
@Injectable({
  providedIn: "root",
})
export class RideService {
  constructor(private api: ApiService, private store: Store) {}

  fetchCompanyRides(company: number | string) {
    return this.api.get(`companies/${company}/rides`).pipe(
      map(({ rides }) =>
        rides.map((ride) => {
          return {
            ...ride,
            company_id: company,
            departure_at: new Date(ride.departure_at),
          } as Ride;
        })
      )
    );
  }

  postEvent(ride: Ride, data: RideEventData) {
    return this.api
      .post(`companies/${ride.company_id}/rides/${ride.id}/event`, data)
      .pipe(map(({ event }) => event as RideEvent));
  }

  postFinishRide(ride: Ride, data: any) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof Blob) {
        formData.append(key, data[key], key);
        return;
      }
      formData.set(key, data[key]);
    });
    return this.api
      .post(`companies/${ride.company_id}/rides/${ride.id}/finish`, formData)
      .pipe(map(({ ride }) => ride as Ride));
  }
  getRideByOrder(rideOrder: RideOrder) {
    let searchCriteria;
    if (rideOrder.order_type === RideOrderType.OFFER) {
      searchCriteria = "offer_ride_order_id";
    } else {
      searchCriteria = "request_ride_order_id";
    }
    return this.store.select(RideSelectors.selectAll).pipe(
      map((rides) => {
        return rides.find((ride) => ride[searchCriteria] === rideOrder.id);
      })
    );
  }
  fetchRide(company: number | string, ride: number | string) {
    return this.api.get(`companies/${company}/rides/${ride}`).pipe(
      map(
        ({ ride }) =>
          ({
            ...ride,
            company_id: company,
            departure_at: new Date(ride.departure_at),
          } as Ride)
      )
    );
  }
}
