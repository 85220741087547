import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY } from "rxjs";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";
import { VehicleTypeActions } from "../actions";
import { VehicleTypeSelectors } from "../selectors";
import { VehicleTypeService } from "../services/vehicle-type.service";

@Injectable()
export class VehicleTypeEffects {
  loadVehicleTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VehicleTypeActions.loadVehicleTypes),
      withLatestFrom(this.store.select(VehicleTypeSelectors.selectLoaded)),
      mergeMap(([action, loaded]) => {
        if (loaded) {
          return EMPTY;
        }
        return this.vehicleTypeService.fetchAll().pipe(map((vehicleTypes) => VehicleTypeActions.loadVehicleTypesSuccess({ vehicleTypes })))
      }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private vehicleTypeService: VehicleTypeService
  ) { }
}
