import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest } from "rxjs";
import { exhaustMap, tap, map } from "rxjs/operators";
import { DeviceActions } from "../actions";
import { AuthActions, DeviceService as DeviceApiService } from "tirup-api";
import { PushActions } from "../../push";
import { DeviceService } from "../services";

@Injectable()
export class DeviceEffects {
  deviceReady$ = createEffect(() =>
    this.device.deviceReady$.pipe(
      map(() => {
        return DeviceActions.deviceReady();
      })
    )
  );
  registerDevice$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(AuthActions.authorized)),
        this.actions$.pipe(ofType(PushActions.pushRegistered)),
        this.device.getDeviceData(),
      ]).pipe(
        exhaustMap(([auth, registration, data]) => {
          return this.deviceApi.registerDevice({
            ...data,
            registration: registration.token
          });
        })
      ),
    { dispatch: false }
  );
  constructor(
    private actions$: Actions,
    private store: Store,
    private deviceApi: DeviceApiService,
    private device: DeviceService
  ) { }
}
