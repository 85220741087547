import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { StockType } from "./stock-type.model";

export const loadStockTypes = createAction("[StockType/API] Load StockTypes");
export const loadStockTypeSuccess = createAction(
  "[StockType/API] Load StockTypes Success",
  props<{ stockTypes: StockType[] }>()
);
export const loadStockTypeFailed = createAction(
  "[StockType/API] Load StockTypes Failed",
  props<{ error: any }>()
);
export const addStockType = createAction(
  "[StockType/API] Add StockType",
  props<{ stockType: StockType }>()
);

export const upsertStockType = createAction(
  "[StockType/API] Upsert StockType",
  props<{ stockType: StockType }>()
);

export const addStockTypes = createAction(
  "[StockType/API] Add StockTypes",
  props<{ stockTypes: StockType[] }>()
);

export const upsertStockTypes = createAction(
  "[StockType/API] Upsert StockTypes",
  props<{ stockTypes: StockType[] }>()
);

export const updatetockType = createAction(
  "[StockType/API] Update StockType",
  props<{ stockType: Update<StockType> }>()
);

export const updatetockTypes = createAction(
  "[StockType/API] Update StockTypes",
  props<{ stockTypes: Update<StockType>[] }>()
);

export const deletetockType = createAction(
  "[StockType/API] Delete StockType",
  props<{ id: string }>()
);

export const deletetockTypes = createAction(
  "[StockType/API] Delete StockTypes",
  props<{ ids: string[] }>()
);

export const clearStockTypes = createAction("[StockType/API] Clear StockTypes");
