import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApiService } from "projects/tirup-api/src/lib/services";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CompanyInviteActions } from "../actions";
import { CompanyInvite } from "../models";
import { CompanyInvitesSelectors } from "../selectors";

@Injectable({
  providedIn: "root",
})
export class CompanyInviteService {
  constructor(private api: ApiService, private store: Store) {}

  fetchCompanyInvites(company: string | number): Observable<CompanyInvite[]> {
    return this.api
      .get(`companies/${company}/invites`, {})
      .pipe(
        map(
          ({ company_user_invites }) => company_user_invites as CompanyInvite[]
        )
      );
  }

  fetchUserInvites(): Observable<CompanyInvite[]> {
    return this.api
      .get(`user/company/invites`, {})
      .pipe(
        map(
          ({ company_user_invites }) => company_user_invites as CompanyInvite[]
        )
      );
  }

  accept(companyInvite: CompanyInvite): Observable<CompanyInvite> {
    return this.api
      .post(
        `companies/${companyInvite.company_id}/invites/${companyInvite.id}/accept`,
        {}
      )
      .pipe(
        map(({ company_user_invite }) => company_user_invite as CompanyInvite),
        tap((companyInvite) =>
          this.store.dispatch(
            CompanyInviteActions.upsertCompanyInvite({ companyInvite })
          )
        )
      );
  }

  decline(companyInvite: CompanyInvite): Observable<CompanyInvite> {
    return this.api
      .post(
        `companies/${companyInvite.company_id}/invites/${companyInvite.id}/decline`,
        {}
      )
      .pipe(
        map(({ company_user_invite }) => company_user_invite as CompanyInvite),
        tap((companyInvite) =>
          this.store.dispatch(
            CompanyInviteActions.upsertCompanyInvite({ companyInvite })
          )
        )
      );
  }

  cancel(companyInvite: CompanyInvite): Observable<CompanyInvite> {
    return this.api
      .post(
        `companies/${companyInvite.company_id}/invites/${companyInvite.id}/cancel`,
        {}
      )
      .pipe(
        map(({ company_user_invite }) => company_user_invite as CompanyInvite),
        tap((companyInvite) =>
          this.store.dispatch(
            CompanyInviteActions.upsertCompanyInvite({ companyInvite })
          )
        )
      );
  }
}
