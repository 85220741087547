import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { CompanyInvite } from "../models/company-invite.model";

export const loadUserCompanyInvitesSuccess = createAction(
  "[CompanyInvite/API] Load User CompanyInvites Success",
  props<{ companyInvites: CompanyInvite[] }>()
);

export const loadUserCompanyInvitesFailed = createAction(
  "[CompanyInvite/API] Load User CompanyInvites Failed",
  props<{ error: any }>()
);

export const loadUserCompanyInvites = createAction(
  "[CompanyInvite/API] Load User CompanyInvites"
);

export const loadCompanyInviteSuccess = createAction(
  "[CompanyInvite/API] Load CompanyInvites Success",
  props<{ companyInvites: CompanyInvite[] }>()
);
export const loadCompanyInviteFailed = createAction(
  "[CompanyInvite/API] Load CompanyInvites Failed",
  props<{ error: any }>()
);

export const loadCompanyInvites = createAction(
  "[CompanyInvite/API] Load CompanyInvites",
  props<{ company: string | number }>()
);

export const addCompanyInvite = createAction(
  "[CompanyInvite/API] Add CompanyInvite",
  props<{ companyInvite: CompanyInvite }>()
);

export const upsertCompanyInvite = createAction(
  "[CompanyInvite/API] Upsert CompanyInvite",
  props<{ companyInvite: CompanyInvite }>()
);

export const addCompanyInvites = createAction(
  "[CompanyInvite/API] Add CompanyInvites",
  props<{ companyInvites: CompanyInvite[] }>()
);

export const upsertCompanyInvites = createAction(
  "[CompanyInvite/API] Upsert CompanyInvites",
  props<{ companyInvites: CompanyInvite[] }>()
);

export const updateCompanyInvite = createAction(
  "[CompanyInvite/API] Update CompanyInvite",
  props<{ companyInvite: Update<CompanyInvite> }>()
);

export const updateCompanyInvites = createAction(
  "[CompanyInvite/API] Update CompanyInvites",
  props<{ companyInvites: Update<CompanyInvite>[] }>()
);

export const deleteCompanyInvite = createAction(
  "[CompanyInvite/API] Delete CompanyInvite",
  props<{ id: string }>()
);

export const deleteCompanyInvites = createAction(
  "[CompanyInvite/API] Delete CompanyInvites",
  props<{ ids: string[] }>()
);

export const clearCompanyInvites = createAction(
  "[CompanyInvite/API] Clear CompanyInvites"
);
