import { createAction, props } from "@ngrx/store";
import { Ride } from "../../ride";

export const set = createAction(
  "[ActiveRide] Set Active Ride",
  props<{ ride: Ride }>()
);
export const update = createAction(
  "[ActiveRide] Update Active Ride",
  props<{ ride: Partial<Ride> }>()
);
export const finished = createAction("[ActiveRide] Active Ride Finished");
export const clear = createAction("[ActiveRide] Clear Active Ride");
export const openRide = createAction("[ActiveRide] Open Active Ride");
