import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { Base64 } from "@ionic-native/base64/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
/**
 * Modules
 */
import { IonicStorageModule } from "@ionic/storage";
/**
 * Providers
 */
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { Crop } from "@ionic-native/crop/ngx";
import { FilePath } from "@ionic-native/file-path/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { File } from "@ionic-native/file/ngx";
/**
 * Services
 */

import { CalendarModule } from "ion2-calendar";
import { TirupApiModule } from "tirup-api";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./reducers";
import { AlertModule } from "./alert/alert.module";
import { GeolocationModule } from "./@core/geolocation";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { PlacesModule } from "./@core/places/places.module";
import { MenuComponent } from "./views/menu/menu.component";
import { PushModule } from "./@core/push";
import { DeviceModule } from "./@core/device/device.module";
import { AppEffects } from "./@core/app.effects";
import { StoreRouterConnectingModule, routerReducer } from "@ngrx/router-store";
import { AccountSetupGuard } from "./@core/guards/account-setup.guard";

@NgModule({
  declarations: [AppComponent, MenuComponent],
  entryComponents: [],
  imports: [
    TirupApiModule.forRoot({
      apiUrl: environment.apiURL,
      nativeRequest: false,
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AppEffects]),
    DeviceModule.forRoot(),
    GeolocationModule.forRoot(),
    BrowserModule,
    IonicModule.forRoot({ mode: "ios", backButtonText: "" }),
    AlertModule.forRoot(),
    StoreDevtoolsModule.instrument(),
    PushModule,
    CalendarModule.forRoot({
      doneLabel: "Save",
      closeIcon: false,
    }),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    WebView,
    Camera,
    Crop,
    File,
    FilePath,
    Base64,
    AccountSetupGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
