import { createReducer, on } from '@ngrx/store';
import * as PushActions from '../actions/push.actions';

export const pushFeatureKey = 'push';

export interface State {
  loaded: boolean;
  token: string;
}

export const initialState: State = {
  loaded: false,
  token: null,
};

export const pushReducer = createReducer(
  initialState,
  on(PushActions.loadPush, (state) => state),
  on(PushActions.pushRegistered, (state, action) => ({ loaded: true, token: action.token }))
);

export function reducer(state: State | undefined, action) {
  return pushReducer(state, action);
}
