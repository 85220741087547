import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { RideOrderData } from "../../../types/ride-order.dto";
import { RideOrder, RideOrderType } from "../../../models";
import { ApiService } from "../../../../../services";
import { Store } from "@ngrx/store";
import { RideOrderSelectors } from "../selectors";
import { RideOrdersActions } from "../actions";

@Injectable({
  providedIn: "root",
})
export class RideOrdersService {
  fetchedRides = [];

  constructor(private api: ApiService, private store: Store) {}

  get(id: string): Observable<RideOrder> {
    return this.store.select(RideOrderSelectors.selectById(id)).pipe(
      switchMap((rideOrder) => {
        if (!rideOrder && !this.fetchedRides.includes(id)) {
          return this.fetchRide(id);
        }
        return of(rideOrder);
      })
    );
  }

  fetchRide(id: number | string): Observable<RideOrder> {
    this.fetchedRides.push(id);
    return this.api.get(`ride-orders/${id}`).pipe(
      map(({ ride_order }) => ride_order as RideOrder),
      tap((rideOrder) =>
        this.store.dispatch(RideOrdersActions.upsertRideOrder({ rideOrder }))
      )
    );
  }

  fetchCompanyRides(company: string) {
    return this.api
      .get(`companies/${company}/ride-orders`)
      .pipe(map(({ ride_orders }) => ride_orders as RideOrder[]));
  }

  cancel(rideOrder: RideOrder) {
    return this.api
      .post(
        `companies/${rideOrder.company_id}/ride-orders/${rideOrder.id}/cancel`,
        {}
      )
      .pipe(
        map(({ ride_order }) => ride_order as RideOrder),
        tap((rideOrder) =>
          this.store.dispatch(RideOrdersActions.upsertRideOrder({ rideOrder }))
        )
      );
  }
  getMatchingType(input: RideOrderType) {
    return Object.values(RideOrderType).find((type) => type !== input);
  }
}
