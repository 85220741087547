import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, switchMap, map } from "rxjs/operators";
import { CompanyUserActions } from "../../company-user";
import { CompanyInviteActions } from "../actions";
import { CompanyInviteService } from "../services";

@Injectable()
export class CompanyInviteEffects {
  loadUserInvites$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyInviteActions.loadUserCompanyInvites),
      switchMap(() =>
        this.service.fetchUserInvites().pipe(
          map((companyInvites) =>
            CompanyInviteActions.loadUserCompanyInvitesSuccess({
              companyInvites,
            })
          ),
          catchError((error) =>
            of(CompanyInviteActions.loadUserCompanyInvitesFailed({ error }))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: CompanyInviteService
  ) {}
}
