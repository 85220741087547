import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Ride } from "../models/ride.model";
import * as RideActions from "../actions/ride.actions";

export const ridesFeatureKey = "rides";

export interface State extends EntityState<Ride> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Ride> = createEntityAdapter<Ride>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(RideActions.addRide, (state, action) =>
    adapter.addOne(action.ride, state)
  ),
  on(RideActions.upsertRide, (state, action) =>
    adapter.upsertOne(action.ride, state)
  ),
  on(RideActions.addRides, (state, action) =>
    adapter.addMany(action.rides, state)
  ),
  on(RideActions.upsertRides, (state, action) =>
    adapter.upsertMany(action.rides, state)
  ),
  on(RideActions.updateRide, (state, action) =>
    adapter.updateOne(action.ride, state)
  ),
  on(RideActions.updateRides, (state, action) =>
    adapter.updateMany(action.rides, state)
  ),
  on(RideActions.deleteRide, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(RideActions.deleteRides, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(RideActions.loadRidesSuccess, (state, action) =>
    adapter.upsertMany(action.rides, state)
  ),
  on(RideActions.clearRides, (state) => adapter.removeAll(state))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
