import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { fromCompanyInvite } from "./reducers";
import { CompanyInviteEffects } from "./effects";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([CompanyInviteEffects]),
    StoreModule.forFeature(
      fromCompanyInvite.companyInvitesFeatureKey,
      fromCompanyInvite.reducer
    ),
  ],
})
export class CompanyInviteModule {}
