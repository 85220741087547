import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../services";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CompanyUser } from "../models";
import { AddCompanyUserDto } from "../types";
import { CompanyUsersDto } from "../types/company-users.dto";
import { Store } from "@ngrx/store";
import { CompanyUserActions } from "../actions";
import { Company } from "../../../models";
import { UserData } from "../../../../account";
import { CompanyUserSelectors } from "../selectors";

@Injectable({
  providedIn: "root",
})
export class CompanyUsersService {
  constructor(private api: ApiService, private store: Store) {}

  create(companyId: number, data: AddCompanyUserDto): Observable<CompanyUser> {
    return this.api.post(`companies/${companyId}/users`, data).pipe(
      map(({ company_user }) => company_user as CompanyUser),
      tap((companyUser) =>
        this.store.dispatch(CompanyUserActions.addCompanyUser({ companyUser }))
      )
    );
  }

  update(
    companyId: number,
    data: Partial<CompanyUsersDto>
  ): Observable<CompanyUser> {
    return this.api.put(`companies/${companyId}/users/${data.id}`, data).pipe(
      map(({ company_user }) => company_user as CompanyUser),
      tap((companyUser) =>
        this.store.dispatch(
          CompanyUserActions.upsertCompanyUser({ companyUser })
        )
      )
    );
  }

  remove(companyId: string | number, id: string | number): Observable<boolean> {
    return this.api
      .delete(`companies/${companyId}/users/${id}`)
      .pipe(map(() => true));
  }

  getByUserId(companyId: string | number, user: string | number) {
    return this.store
      .select(CompanyUserSelectors.selectAllFromCompany(companyId))
      .pipe(
        map((users) => {
          return users.find((u) => u.user_id == user);
        })
      );
  }
  get(
    companyId: string | number,
    id: string | number
  ): Observable<CompanyUser> {
    return this.api
      .get(`companies/${companyId}/users/${id}`)
      .pipe(map(({ company_user }) => company_user as CompanyUser));
  }

  getAll(companyId: string | number): Observable<CompanyUser[]> {
    return this.api
      .get(`companies/${companyId}/users`)
      .pipe(map(({ company_users }) => company_users as CompanyUser[]));
  }

  getUserRole(company: Company, user: UserData) {
    return this.getByUserId(company.id, user.id).pipe(
      map((companyUser) => companyUser?.role)
    );
  }
}
