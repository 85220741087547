import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserService } from "./services";

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class UsersModule {
  public static forRoot(): ModuleWithProviders<UsersModule> {
    return {
      ngModule: CommonModule,
      providers: [UserService],
    };
  }
}
