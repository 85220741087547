import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { reducer, companyEventsFeatureKey } from "./reducers";
import { StoreModule } from "@ngrx/store";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([EffectsModule]),
    StoreModule.forFeature(companyEventsFeatureKey, reducer),
  ],
})
export class CompanyEventsModule {}
