import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromRideSetup from "./ride-setup.reducer";

export const selectRideSetupState = createFeatureSelector<fromRideSetup.State>(
  fromRideSetup.rideSetupFeatureKey
);

export const selectRideOrderData = createSelector(
  selectRideSetupState,
  (state: fromRideSetup.State) => state.rideOrder
);

export const selectRouteData = createSelector(
  selectRideSetupState,
  (state: fromRideSetup.State) => state.rideRoute
);

const selectWaypointsAdapter = createSelector(
  selectRideSetupState,
  (state: fromRideSetup.State) => state.routeWaypoints
);

export const selectAllWaypoints = createSelector(
  selectWaypointsAdapter,
  fromRideSetup.selectAll
);

export const selectWaypointsEntities = createSelector(
  selectWaypointsAdapter,
  fromRideSetup.selectEntities
);

export const selectWaypointsTotal = createSelector(
  selectWaypointsAdapter,
  fromRideSetup.selectTotal
);

export const selectWaypointsIds = createSelector(
  selectWaypointsAdapter,
  fromRideSetup.selectTotal
);
