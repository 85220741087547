import { Injectable } from "@angular/core";
import { from } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlaceAutocompleteService {
  autocompleteService = new google.maps.places.AutocompleteService();
  constructor() {}
  private searchPlaces(
    query: google.maps.places.AutocompletionRequest
  ): Promise<google.maps.places.QueryAutocompletePrediction[]> {
    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(query, (res, err) => {
        if (err !== "OK") {
          reject(err);
        }
        resolve(res);
      });
    });
  }
  searchPlacesByAddress(input: string) {
    return from(
      this.searchPlaces({ input, componentRestrictions: { country: "RO" } })
    );
  }
}
