import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { Device } from "@ionic-native/device/ngx";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { DeviceEffects } from "./effects";
import { deviceFeatureKey, reducer } from "./reducers/device.reducer";
import { DeviceService } from "./services/device.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([DeviceEffects]),
    StoreModule.forFeature(deviceFeatureKey, reducer),
  ],
})
export class DeviceModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: DeviceModule,
      providers: [DeviceService, Device],
    };
  }
}
