import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ApiService } from "../../../../../services";
import { RideOrderCall, RideOrderCallStatus } from "../../../models";
import { RideActions } from "../../ride/actions";

@Injectable({
  providedIn: "root",
})
export class RideOrderCallService {
  constructor(private api: ApiService, private store: Store) {}

  getRideCalls(target: number): Observable<RideOrderCall[]> {
    return this.api
      .get(`ride-order/${target}/calls`)
      .pipe(map(({ ride_order_calls }) => ride_order_calls));
  }

  getCalls(sender: number, target: number): Observable<RideOrderCall[]> {
    const data = {
      sender_ride_order_id: sender,
      target_ride_order_id: target,
    };
    return this.api
      .get("ride-order-calls", data)
      .pipe(map(({ ride_order_calls }) => ride_order_calls));
  }

  create(sender: number, target: number): Observable<RideOrderCall> {
    const data = {
      sender_ride_order_id: sender,
      target_ride_order_id: target,
    };
    return this.api
      .post("ride-order-calls", data)
      .pipe(map(({ ride_order_call }) => ride_order_call));
  }

  getLatestCall(sender: number, target: number) {
    return this.getCalls(sender, target).pipe(map((calls) => calls[0]));
  }

  update(data: Partial<RideOrderCall>): Observable<RideOrderCall> {
    return this.api
      .put(`ride-order-calls/${data.id}`, data)
      .pipe(map(({ ride_order_call }) => ride_order_call as RideOrderCall));
  }

  remove(id: number): Observable<boolean> {
    return this.api.delete(`ride-order-calls/${id}`).pipe(map(() => true));
  }

  get(id: number): Observable<RideOrderCall> {
    return this.api
      .get(`ride-order-calls/${id}`)
      .pipe(map(({ ride_order_call }) => ride_order_call as RideOrderCall));
  }

  getAll(): Observable<RideOrderCall[]> {
    return this.api
      .get("ride-order-calls")
      .pipe(map(({ ride_order_calls }) => ride_order_calls as RideOrderCall[]));
  }

  acceptRideOrderCall(rideOrderCall: RideOrderCall) {
    return this.update({
      id: rideOrderCall.id,
      status: RideOrderCallStatus.APPROVED,
    }).pipe(
      tap(() => {
        this.store.dispatch(
          RideActions.loadRides({
            company: rideOrderCall.target_company_id,
          })
        );
      })
    );
  }

  rejectRideOrderCall(rideOrderCall: RideOrderCall) {
    return this.update({
      id: rideOrderCall.id,
      status: RideOrderCallStatus.REJECTED,
    });
  }
}
