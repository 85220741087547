import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../../../../../services";
import { CompanyRolePermission } from "../models";

@Injectable({
  providedIn: "root",
})
export class CompanyRolePermissionsService {
  constructor(private api: ApiService) {}

  getAll(): Observable<CompanyRolePermission[]> {
    return this.api
      .get("company-role-permissions")
      .pipe(
        map(
          ({ company_role_permissions }) =>
            company_role_permissions as CompanyRolePermission[]
        )
      );
  }
}
