import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  concatMap,
  withLatestFrom,
  mergeMap,
} from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import { UserCompanyActions } from "../actions";
import { UserCompanyService } from "../services/user-company.service";
import { AuthActions } from "../../authentication";
import { Store } from "@ngrx/store";
import { UserCompanySelectors } from "../selectors";
import {
  CompanyUser,
  CompanyUserActions,
} from "../../company/@modules/company-user";
import * as _ from "lodash";

@Injectable()
export class UserCompanyEffects {
  setCurrentCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserCompanyActions.setCurrentCompany),
      withLatestFrom(this.store.select(UserCompanySelectors.selectCurrentId)),
      mergeMap(([action, currentId]) => {
        if (action.id !== currentId) {
          return of(
            UserCompanyActions.currentCompanyChanged({ id: action.id })
          );
        }
        return EMPTY;
      })
    );
  });

  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserCompanyActions.loadUserCompanies),
      concatMap(() =>
        this.userCompanyService.getAll().pipe(
          mergeMap((companies) => {
            const companyUsers: CompanyUser[] = _.flatten(
              companies.map((data) => data.company_users)
            );
            return [
              CompanyUserActions.upsertCompanyUsers({ companyUsers }),
              UserCompanyActions.loadUserCompaniesSuccess({ companies }),
            ];
          }),
          catchError((error) =>
            of(UserCompanyActions.loadUserCompaniesFailed({ error }))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private userCompanyService: UserCompanyService,
    private store: Store
  ) {}
}
