import { Injectable } from "@angular/core";
import {
  BackgroundGeolocation,
  BackgroundGeolocationConfig,
  BackgroundGeolocationEvents,
  BackgroundGeolocationResponse,
} from "@ionic-native/background-geolocation/ngx";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { AppState } from "src/app/reducers";
import { environment } from "src/environments/environment";
import { LocationActions } from "../actions";

@Injectable({
  providedIn: "root",
})
export class BackgroundGeolocationService {
  private backgroundLocagionUrl = `${environment.apiURL}/api/user/location`;

  constructor(
    public geolocation: BackgroundGeolocation,
    private store: Store<AppState>,
    private platform: Platform
  ) {}
  private config: BackgroundGeolocationConfig = {
    desiredAccuracy: 0,
    stationaryRadius: 10,
    distanceFilter: 30,
    interval: 1000,
    fastestInterval: 1000,
    activitiesInterval: 1000,
    debug: false, //  enable this hear sounds for background-geolocation life-cycle.
    stopOnTerminate: true, // enable this to clear background location settings when the app terminates
    url: this.backgroundLocagionUrl,
    postTemplate: {
      lat: "@latitude",
      lng: "@longitude",
      heading: "0", // you can also add your own properties
      speed: "0", // you can also add your own properties
    },
  };

  async init() {
    await this.geolocation.configure(this.config);
  }

  locationMap() {
    return map((location: BackgroundGeolocationResponse) => {
      return {
        lat: location.latitude,
        lng: location.longitude,
        accuracy: location.accuracy,
        altitude: location.altitude,
        heading: location.bearing,
        speed: location.speed,
      };
    });
  }

  startWatching() {
    this.geolocation.start();
    this.store.dispatch(LocationActions.getCurrentLocation());
    this.geolocation
      .on(BackgroundGeolocationEvents.location)
      .pipe(this.locationMap())
      .subscribe(
        (location) => {
          this.store.dispatch(LocationActions.update({ location }));
          if (this.platform.is("ios")) {
            this.geolocation.finish(); // FOR IOS ONLY
          }
        },
        (err) => console.error(err)
      );
  }

  async setHttpHeaders(token: string) {
    this.geolocation.configure({
      ...this.config,
      httpHeaders: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  }
}
