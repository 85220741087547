import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import {
  RideOrder,
  RideOrderType,
  RideRoute,
  RideWaypoint,
} from "../../models";
import { RideOrderData, RideRouteData } from "../../types";
import * as moment from "moment";
import * as RideSetupActions from "./ride-setup.actions";
export const rideSetupFeatureKey = "rideSetup";
export interface State {
  rideOrder: RideOrderData;
  rideRoute: RideRouteData;
  routeWaypoints: EntityState<RideWaypoint>;
}
export const waypointAdapter: EntityAdapter<RideWaypoint> =
  createEntityAdapter<RideWaypoint>({
    selectId: (waypoint) => waypoint.id,
  });
export const initialState: State = {
  rideOrder: {
    space_type: null,
    order_type: null,
    max_detour_distance: 1,
    departure_at: moment().add(1, "d").toDate(),
    arrival_at: null,
    cargo_volume: 0,
    floor_length: 0,
    cargo_weight: 0,
    vehicle_type: "",
    stock_type_id: undefined,
    stock_details: "",
  },
  rideRoute: null,
  routeWaypoints: waypointAdapter.getInitialState({}),
};

export const reducer = createReducer(
  initialState,
  on(RideSetupActions.addWaypoint, (state, { waypoint }) => {
    return {
      ...state,
      routeWaypoints: waypointAdapter.addOne(waypoint, state.routeWaypoints),
    };
  }),
  on(RideSetupActions.removeWaypoint, (state, { id }) => {
    return {
      ...state,
      routeWaypoints: waypointAdapter.removeOne(id, state.routeWaypoints),
    };
  }),
  on(RideSetupActions.clearWaypoints, (state) => {
    return {
      ...state,
      routeWaypoints: waypointAdapter.removeAll(state.routeWaypoints),
    };
  }),
  on(RideSetupActions.updateWaypoint, (state, { update }) => {
    return {
      ...state,
      routeWaypoints: waypointAdapter.updateOne(update, state.routeWaypoints),
    };
  }),
  on(RideSetupActions.updateRideOrder, (state, { rideOrder }) => {
    const newRideOrder = Object.assign({}, state.rideOrder, rideOrder);
    return {
      ...state,
      rideOrder: newRideOrder,
    };
  }),
  on(RideSetupActions.updateRideRoute, (state, { rideRoute }) => {
    const newRideRoute = Object.assign({}, state.rideRoute, rideRoute);
    return {
      ...state,
      rideRoute: newRideRoute,
    };
  }),
  on(RideSetupActions.generateRouteSuccess, (state, { rideRoute }) => {
    const newRideRoute = Object.assign({}, state.rideRoute, rideRoute);
    return {
      ...state,
      rideRoute: newRideRoute,
    };
  }),
  on(RideSetupActions.initRideOrder, (state, { space, orderType }) => {
    const newRideOder: RideOrderData = {
      ...initialState.rideOrder,
      order_type: orderType,
      space_type: space,
    };
    return {
      ...initialState,
      rideOrder: newRideOder,
    };
  })
);

// get the selectors
export const { selectIds, selectEntities, selectAll, selectTotal } =
  waypointAdapter.getSelectors();
