import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { StockType } from "./stock-type.model";
import * as StockTypeActions from "./stock-type.actions";

export const stockTypeFeatureKey = "stockType";

export interface State extends EntityState<StockType> {
  // additional entitie state propertie
  loaded: boolean;
}

export const adapter: EntityAdapter<StockType> = createEntityAdapter<StockType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state propertie
  loaded: false,
});

export const reducer = createReducer(
  initialState,
  on(StockTypeActions.addStockType, (state, action) =>
    adapter.addOne(action.stockType, state)
  ),
  on(StockTypeActions.upsertStockType, (state, action) =>
    adapter.upsertOne(action.stockType, state)
  ),
  on(StockTypeActions.addStockTypes, (state, action) =>
    adapter.addMany(action.stockTypes, state)
  ),
  on(StockTypeActions.upsertStockTypes, (state, action) =>
    adapter.upsertMany(action.stockTypes, state)
  ),
  on(StockTypeActions.updatetockType, (state, action) =>
    adapter.updateOne(action.stockType, state)
  ),
  on(StockTypeActions.updatetockTypes, (state, action) =>
    adapter.updateMany(action.stockTypes, state)
  ),
  on(StockTypeActions.deletetockType, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(StockTypeActions.deletetockTypes, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(StockTypeActions.loadStockTypeSuccess, (state, action) =>
    adapter.setAll(action.stockTypes, { ...state, loaded: true })
  ),
  on(StockTypeActions.clearStockTypes, (state) =>
    adapter.removeAll({ ...state, loaded: false })
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
