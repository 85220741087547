import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RideOrdersModule } from "./@modules/ride-orders";
import { RideSetupModule } from "./@modules/ride-setup/ride-setup.module";
import { ActiveRideModule } from "./@modules/active-ride";
import { RideSearchModule } from "./@modules/ride-search";
import { RideOrderCallModule } from "./@modules/ride-order-call";
import { RideModule } from "./@modules/ride";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RideSetupModule,
    RideSearchModule,
    RideOrdersModule,
    RideOrderCallModule,
    ActiveRideModule,
    RideModule,
  ],
})
export class RidesModule {}
