import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CompanySearchService } from "./services";

@NgModule({
  declarations: [],
  providers: [CompanySearchService],
  exports: [],
  imports: [CommonModule],
})
export class CompanySearchModule {}
