import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { catchError, concatMap, delay, map, mergeMap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import { RideSearchService } from "./ride-search.service";
import { RideSearchResultActions } from "./actions";

@Injectable()
export class RideSearchEffects {
  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RideSearchResultActions.loadRideSearchResults),
      mergeMap(({ subject }) =>
        this.searchService
          .getMatchingRides(subject.company_id, subject.id)
          .pipe(
            map((results) =>
              RideSearchResultActions.loadRideSearchResultsSuccess({ results })
            ),
            catchError((error) =>
              of(RideSearchResultActions.loadRideSearchResultsFailed({ error }))
            )
          )
      )
    );
  });

  search$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RideSearchResultActions.searchRideOrders),
      mergeMap(({ filters }) =>
        this.searchService.search(filters).pipe(
          map((results) =>
            RideSearchResultActions.loadRideSearchResultsSuccess({ results })
          ),
          catchError((error) =>
            of(RideSearchResultActions.loadRideSearchResultsFailed({ error }))
          )
        )
      )
    );
  });
  constructor(
    private actions$: Actions,
    private searchService: RideSearchService
  ) {}
}
