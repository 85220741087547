import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../../../services";
import { VehicleType } from "../models/vehicle-type.model";

@Injectable({
  providedIn: "root",
})
export class VehicleTypeService {
  constructor(private api: ApiService) {}

  public fetchAll(): Observable<VehicleType[]> {
    return this.api
      .get("vehicle/types")
      .pipe(map(({ vehicle_types }) => vehicle_types));
  }
}
