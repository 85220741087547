import { Injectable } from "@angular/core";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthActions } from "tirup-api";
import { combineLatest, EMPTY, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { DeviceActions } from "src/app/@core/device/actions";
import { LocationActions } from "../actions";
import { BackgroundGeolocationService } from "../services/background-geolocation.service";
import {
  LocationPermissionService,
} from "../services/location-permissions.service";

@Injectable()
export class LocationEffects {
  request$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(DeviceActions.deviceReady)), //
        this.actions$.pipe(ofType(LocationActions.request)),
      ]).pipe(
        withLatestFrom(
          this.locationPermissions.getPermissionStatus(),
          this.locationPermissions.isLocationEnabled()
        ),
        exhaustMap(([action, permission, enabled]) => {
          if (
            permission !== this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE &&
            permission !== this.diagnostic.permissionStatus.GRANTED
          ) {
            return of(LocationActions.requestPermissions());
          }
          if (!enabled) {
            return of(LocationActions.requestGPS());
          }
          return of(LocationActions.watch());
        })
      ),
    { dispatch: true }
  );

  requestPermission$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationActions.requestPermissions),
        exhaustMap((action) =>
          this.locationPermissions.requestPermissions().pipe(
            map((result) => {
              switch (result) {
                case this.diagnostic.permissionStatus.RESTRICTED:
                case this.diagnostic.permissionStatus.DENIED_ALWAYS:
                  return LocationActions.permissionFailed();
                case this.diagnostic.permissionStatus.DENIED_ONCE:
                  return LocationActions.requestPermissions();
                case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                case this.diagnostic.permissionStatus.GRANTED:
                  return LocationActions.permissionGranted();
                default:
                  return null;
              }
            }),
            catchError(() => {
              return EMPTY;
            })
          )
        )
      ),
    { dispatch: true }
  );

  requestGps$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationActions.requestGPS),
        exhaustMap((action) => {
          return this.locationPermissions.enableLocation().pipe(
            map((result) => {
              return LocationActions.requestGpsSuccess();
            }),
            catchError(() => {
              return of(LocationActions.requestGpsFailed());
            })
          );
        })
      ),
    { dispatch: true }
  );

  requestPermissionsGranted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationActions.permissionGranted),
        exhaustMap((action) => {
          return of(LocationActions.request());
        })
      ),
    { dispatch: true }
  );

  requestGpsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationActions.requestGpsSuccess),
        exhaustMap((action) => {
          return of(LocationActions.request());
        })
      ),
    { dispatch: true }
  );

  loadAccountSuccess$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(DeviceActions.deviceReady)),
      ]).pipe(
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        tap((action) => {
          this.location.init();
        })
      ),
    { dispatch: false }
  );

  watch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationActions.watch),
        tap((action) => {
          this.location.startWatching();
        })
      ),
    { dispatch: false }
  );

  authorized$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(DeviceActions.deviceReady)),
        this.actions$.pipe(ofType(AuthActions.authorized)),
      ]).pipe(
        tap(([deviceReady, action]) => {
          this.location.setHttpHeaders(action.token);
        })
      ),
    { dispatch: false }
  );

  // updated$ = createEffect(
  //   () => this.actions$.pipe(ofType(LocationActions.update), tap((a)=>{
  //     console.log(a);
  //   })),
  //   { dispatch: false }
  // );

  constructor(
    private actions$: Actions,
    private diagnostic: Diagnostic,
    private location: BackgroundGeolocationService,
    private locationPermissions: LocationPermissionService
  ) { }
}
