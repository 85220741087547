import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";

import * as StockTypeActions from "./stock-type.actions";
import * as StockTypeSelectors from "./stock-type.selectors";
import { StockTypeService } from "./stock-type.service";
@Injectable()
export class StockTypeEffects {
  loadStockTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StockTypeActions.loadStockTypes),
      withLatestFrom(
        this.store.select(StockTypeSelectors.selectStockTypesLoaded)
      ),
      mergeMap(([action, loaded]) => {
        if (loaded) {
          return EMPTY;
        }
        return this.stockTypeService.getAll().pipe(
          map((stockTypes) =>
            StockTypeActions.loadStockTypeSuccess({ stockTypes })
          ),
          catchError((error) =>
            of(StockTypeActions.loadStockTypeFailed({ error }))
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private stockTypeService: StockTypeService
  ) {}
}
