import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../services";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CompanyVehicle } from "../models";
import { CompanyVehiclesDto } from "../types/company-vehicles.dto";
import { Store } from "@ngrx/store";
import { CompanyVehicleActions } from "../actions";

@Injectable({
  providedIn: "root",
})
export class CompanyVehiclesService {
  constructor(private api: ApiService, private store: Store) {}

  create(
    companyId: number,
    data: CompanyVehiclesDto
  ): Observable<CompanyVehicle> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof Blob) {
        formData.append(key, data[key], key);
        return;
      }
      formData.set(key, data[key]);
    });
    return this.api.post(`companies/${companyId}/vehicles`, formData).pipe(
      map(({ vehicle }) => vehicle as CompanyVehicle),
      tap((companyVehicle) => {
        this.store.dispatch(
          CompanyVehicleActions.upsertCompanyVehicle({ companyVehicle })
        );
      })
    );
  }

  update(
    companyId: number,
    data: CompanyVehiclesDto
  ): Observable<CompanyVehicle> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof Blob) {
        formData.append(key, data[key], key);
        return;
      }
      formData.set(key, data[key]);
    });
    formData.append("_method", "PUT");
    return this.api
      .post(`companies/${companyId}/vehicles/${data.id}`, formData)
      .pipe(
        map(({ vehicle }) => vehicle as CompanyVehicle),
        tap((vehicle) => {
          this.store.dispatch(
            CompanyVehicleActions.updateCompanyVehicle({
              companyVehicle: { id: vehicle.id, changes: vehicle },
            })
          );
        })
      );
  }

  remove(companyId: number, id: number): Observable<boolean> {
    return this.api
      .delete(`companies/${companyId}/vehicles/${id}`)
      .pipe(map(() => true));
  }

  get(companyId: number, id: number): Observable<CompanyVehicle> {
    return this.api
      .get(`companies/${companyId}/vehicles/${id}`)
      .pipe(map(({ vehicle }) => vehicle as CompanyVehicle));
  }

  getAll(companyId: number | string): Observable<CompanyVehicle[]> {
    return this.api
      .get(`companies/${companyId}/vehicles`)
      .pipe(map(({ vehicles }) => vehicles as CompanyVehicle[]));
  }
}
