import { Injectable } from "@angular/core";
import { FirebaseX, IChannelOptions } from "@ionic-native/firebase-x/ngx";
import { Store } from "@ngrx/store";
import { fromEvent } from "rxjs";
import { first, shareReplay, switchMap } from "rxjs/operators";
import { PushActions } from "../actions";

const channels: Array<IChannelOptions> = [
  {
    id: "general",
    name: "general",
    description: "General notification data.",
    importance: 4,
    sound: "beep",
  },
];

const topics: Array<string> = ["general"];
@Injectable({
  providedIn: "root",
})
export class PushService {
  deviceReady$ = fromEvent(document, "deviceready").pipe(
    first(),
    shareReplay()
  );

  constructor(public firebase: FirebaseX, private store: Store) {
    this.deviceReady$
      .pipe(switchMap((_) => this.firebase.onMessageReceived()))
      .subscribe((data) => this.handlePushNotification(data));
  }
  private handlePushNotification(notification: any) {
    if (!notification) {
      return;
    }
    if (notification?.tap) {
      this.store.dispatch(PushActions.pushNotificationTapped({ notification }));
      return;
    }
    this.store.dispatch(PushActions.pushNotificationReceived({ notification }));
  }
  async register() {
    await this.deviceReady$.toPromise();
    await this.requestPermissions();
    const token = await this.firebase.getToken();
    this.store.dispatch(PushActions.pushRegistered({ token }));
    this.firebase.onTokenRefresh().subscribe((token) => {
      this.store.dispatch(PushActions.pushRegistered({ token: token }));
    });
  }

  async registerChannels() {
    for (const channel of channels) {
      await this.firebase.createChannel(channel);
    }
  }

  async registerTopics() {
    for (const topic of topics) {
      await this.firebase.subscribe(topic);
    }
  }

  private async requestPermissions() {
    let permission = await this.firebase.hasPermission();
    if (permission) {
      console.log("We have permission to send push notifications");
    } else {
      console.log("We do not have permission to send push notifications");
      console.log(await this.firebase.grantPermission());
    }
  }
}
