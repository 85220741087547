// Actions
// Models

import { Action, createReducer, on } from '@ngrx/store';
import { LocationActions } from '../actions';
import { LocationData } from '../types';

export interface LocationState {
  ready: boolean;
  loading: boolean;
  location: LocationData;
  permissions: boolean;
}

const initialState: LocationState = {
  ready: false,
  loading: true,
  location: {
    lat: 0,
    lng: 0,
    speed: 0,
    heading: 0,
    altitude: 0,
    altitudeAccuracy: 0,
    accuracy: 0,
  },
  permissions: false,
};

const reducer = createReducer(
  initialState,
  on(LocationActions.update, (state, { location }) => {
    return { ...state, loading: false, location };
  }),
  on(LocationActions.watch, (state) => {
    return { ...state, ready: true };
  }),
  on(LocationActions.permissionGranted, (state) => {
    return { ...state, permissions: true };
  })
);

export function locationReducer(state: undefined | LocationState, action: Action): LocationState {
  return reducer(state, action);
}
