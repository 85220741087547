import { createFeatureSelector, createSelector } from "@ngrx/store";
import { fromCompanyUser } from "../reducers";

export const selectState = createFeatureSelector<fromCompanyUser.State>(
    fromCompanyUser.companyUsersFeatureKey
);

// export const selectLoaded = createSelector(
//   selectState,
//   (state: fromVehicleType.State) => state.loaded
// );
// export const selectLoading = createSelector(
//   selectState,
//   (state: fromVehicleType.State) => !state.loaded
// );

export const selectAll = createSelector(selectState, fromCompanyUser.selectAll);

export const selectEntities = createSelector(
    selectState,
    fromCompanyUser.selectEntities
);

export const selectTotal = createSelector(
    selectState,
    fromCompanyUser.selectTotal
);

export const selectIds = createSelector(
    selectState,
    fromCompanyUser.selectTotal
);

export const selectAllFromCompany = (id) =>
    createSelector(selectAll, (objects) =>
        objects.filter((objects) => objects.company_id == id)
    );

export const selectId = (id) =>
    createSelector(selectEntities, (objects) =>
        objects[id]
    );