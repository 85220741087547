import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../services";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CompanyEvent } from "../models";
@Injectable({
  providedIn: "root",
})
export class CompanyEventsService {
  constructor(private api: ApiService) {}

  fetch(company: string): Observable<CompanyEvent[]> {
    return this.api
      .get(`companies/${company}/events`)
      .pipe(map(({ company_events }) => company_events as CompanyEvent[]));
  }
}
