import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
  StorageService,
  StorageKey,
} from "projects/tirup-api/src/lib/services";
import { EMPTY, from, of } from "rxjs";
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { AuthActions } from "../../../authentication";
import { RideActions, RideService } from "../ride";
import { ActiveRideActions } from "./actions";
import { ActiveRideSelectors } from "./selectors";

@Injectable()
export class ActiveRideEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authorized),
      switchMap(() => from(this.storage.getData(StorageKey.ACTIVE_RIDE))),
      concatMap((rideData) => {
        if (rideData) {
          return of(ActiveRideActions.set({ ride: rideData }));
        }
        return EMPTY;
      })
    )
  );
  setRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveRideActions.set),
      tap(({ ride }) => {
        this.storage.setData(StorageKey.ACTIVE_RIDE, ride);
      }),
      exhaustMap(({ ride }) => {
        return this.rideService.fetchRide(ride.company_id, ride.id);
      }),
      map((ride) => ActiveRideActions.update({ ride })),
      catchError((ride) => ActiveRideActions.clear)
    )
  );
  rideFinished$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActiveRideActions.finished),
      withLatestFrom(this.store.select(ActiveRideSelectors.ride)),
      mergeMap(([action, ride]) => [
        RideActions.loadRides({ company: ride.company_id }),
        ActiveRideActions.clear(),
      ])
    );
  });
  updateRide$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActiveRideActions.update),
        withLatestFrom(this.store.select(ActiveRideSelectors.ride)),
        tap(([action, ride]) => {
          this.storage.setData(StorageKey.ACTIVE_RIDE, ride);
        })
      ),
    { dispatch: false }
  );

  clearActiveRide$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActiveRideActions.clear),
        tap(() => {
          this.storage.clearData(StorageKey.ACTIVE_RIDE);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private storage: StorageService,
    private rideService: RideService,
    private store: Store
  ) {}
}
