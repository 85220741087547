import { Action, createReducer, on } from "@ngrx/store";
import { Ride } from "../ride";
import { ActiveRideActions } from "./actions";

export const activeRideFeatureKey = "activeRide";

export interface State {
  ride: Ride;
}

export const initialState: State = {
  ride: null,
};

export const reducer = createReducer(
  initialState,
  on(ActiveRideActions.set, (state, action) => ({
    ...state,
    ride: action.ride,
  })),
  on(ActiveRideActions.update, (state, action) => {
    const ride = Object.assign({}, state.ride, action.ride);
    return {
      ...state,
      ride,
    };
  })
);
