import { createFeatureSelector, createSelector } from "@ngrx/store";
import { fromCompanyVehicle } from "../reducers";

export const selectState = createFeatureSelector<fromCompanyVehicle.State>(
  fromCompanyVehicle.companyVehiclesFeatureKey
);

// export const selectLoaded = createSelector(
//   selectState,
//   (state: fromVehicleType.State) => state.loaded
// );
// export const selectLoading = createSelector(
//   selectState,
//   (state: fromVehicleType.State) => !state.loaded
// );

export const selectAll = createSelector(
  selectState,
  fromCompanyVehicle.selectAll
);

export const selectEntities = createSelector(
  selectState,
  fromCompanyVehicle.selectEntities
);

export const selectTotal = createSelector(
  selectState,
  fromCompanyVehicle.selectTotal
);

export const selectIds = createSelector(
  selectState,
  fromCompanyVehicle.selectTotal
);

export const selectAllFromCompany = (id) =>
  createSelector(selectAll, (objects) =>
    objects.filter((objects) => objects.company_id == id)
  );
export const selectById = (id) =>
  createSelector(selectEntities, (objects) => {
    return objects[id];
  });
