export const rideSetupTypes = {
  offer: {
    full: {
      steps: [
        {
          name: "departure-location",
        },
        {
          name: "arrival-location",
        },
        {
          name: "route-deviation",
        },

        {
          name: "departure-date",
        },
        {
          name: "vehicle-type",
        },
        {
          name: "select-driver",
        },
      ],
    },
    partial: {
      steps: [
        {
          name: "departure-location",
        },
        {
          name: "arrival-location",
        },
        {
          name: "route-deviation-points",
        },
        {
          name: "route-deviation",
        },
        {
          name: "departure-date",
        },
        {
          name: "arrival-date",
        },
        {
          name: "available-space",
        },
        {
          name: "select-driver",
        },
      ],
    },
  },
  request: {
    full: {
      steps: [
        {
          name: "departure-location",
        },
        {
          name: "arrival-location",
        },
        {
          name: "departure-date",
        },
        {
          name: "arrival-date",
        },
        {
          name: "available-space",
        },
        {
          name: "cargo-details",
        },
      ],
    },
    partial: {
      steps: [
        {
          name: "departure-location",
        },
        {
          name: "arrival-location",
        },
        {
          name: "departure-date",
        },
        {
          name: "arrival-date",
        },
        {
          name: "vehicle-type",
        },
        {
          name: "cargo-details",
        },
      ],
    },
  },
};
