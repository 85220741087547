import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../../../services/api.service";
import { UserData } from "../../account";
import { PublicUserData } from "../models/public-user.model";
import { UserDto } from "../types/users.dto";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private api: ApiService) {}

  create(data: UserDto): Observable<PublicUserData> {
    return this.api
      .post("publicUsers", data)
      .pipe(map(({ publicUser }) => publicUser as PublicUserData));
  }

  update(data: UserDto): Observable<PublicUserData> {
    return this.api
      .put(`publicUsers/${data.id}`, data)
      .pipe(map(({ publicUser }) => publicUser as PublicUserData));
  }

  remove(id: number): Observable<boolean> {
    return this.api.delete(`publicUsers/${id}`).pipe(map(() => true));
  }

  get(id: number): Observable<PublicUserData> {
    return this.api
      .get(`publicUsers/${id}`)
      .pipe(map(({ publicUser }) => publicUser as PublicUserData));
  }

  getAll(): Observable<PublicUserData[]> {
    return this.api
      .get("publicUsers")
      .pipe(map(({ publicUsers }) => publicUsers as PublicUserData[]));
  }

  getName(user: UserData | PublicUserData): string {
    if (!user) {
      return null;
    }
    if (user.username) {
      return user.username;
    }
    if (user.name) {
      return user.name;
    }
    if (user.email) {
      return user.email;
    }
    if (user.phone) {
      return user.phone;
    }
    return "Fara nume.";
  }
}
