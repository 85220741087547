import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { CompanyEvent } from "../models/company-event.model";

export const loadCompanyEvents = createAction(
  "[CompanyEvent/API] Load CompanyEvents",
  props<{ companyEvents: CompanyEvent[] }>()
);

export const addCompanyEvent = createAction(
  "[CompanyEvent/API] Add CompanyEvent",
  props<{ companyEvent: CompanyEvent }>()
);

export const upsertCompanyEvent = createAction(
  "[CompanyEvent/API] Upsert CompanyEvent",
  props<{ companyEvent: CompanyEvent }>()
);

export const addCompanyEvents = createAction(
  "[CompanyEvent/API] Add CompanyEvents",
  props<{ companyEvents: CompanyEvent[] }>()
);

export const upsertCompanyEvents = createAction(
  "[CompanyEvent/API] Upsert CompanyEvents",
  props<{ companyEvents: CompanyEvent[] }>()
);

export const updateCompanyEvent = createAction(
  "[CompanyEvent/API] Update CompanyEvent",
  props<{ companyEvent: Update<CompanyEvent> }>()
);

export const updateCompanyEvents = createAction(
  "[CompanyEvent/API] Update CompanyEvents",
  props<{ companyEvents: Update<CompanyEvent>[] }>()
);

export const deleteCompanyEvent = createAction(
  "[CompanyEvent/API] Delete CompanyEvent",
  props<{ id: string }>()
);

export const deleteCompanyEvents = createAction(
  "[CompanyEvent/API] Delete CompanyEvents",
  props<{ ids: string[] }>()
);

export const clearCompanyEvents = createAction(
  "[CompanyEvent/API] Clear CompanyEvents"
);
