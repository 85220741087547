import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AccountSelectors, UserData } from "tirup-api";

@Injectable({
  providedIn: "root",
})
export class AccountSetupGuard implements CanActivate {
  user$: Observable<UserData>;

  constructor(private store: Store, private router: Router) {
    this.user$ = combineLatest([
      this.store.select(AccountSelectors.selectUser),
      this.store.select(AccountSelectors.selectLoaded),
    ]).pipe(
      filter(([user, loaded]) => loaded),
      map(([user, _loaded]) => user)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.user$.pipe(
      map((user) => {
        if (!user) {
          return false;
        }
        if (!user.username) {
          return this.router.parseUrl(`/account-setup/username`);
        }
        if (!user.email) {
          return this.router.parseUrl(`/account-setup/data`);
        }
        return true;
      })
    );
  }
}
