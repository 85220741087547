import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { RideSearchResult } from "./ride-search-result.model";
import * as RideSearchResultActions from "./actions/ride-search-result.actions";
import { RideOrder } from "../../models";

export const rideSearchResultsFeatureKey = "rideSearchResults";

export interface State extends EntityState<RideSearchResult> {
  // additional entities state properties
  searchSubject: RideOrder;
  request: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<RideSearchResult> =
  createEntityAdapter<RideSearchResult>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  searchSubject: null,
  request: false,
  loaded: false,
});

export const reducer = createReducer(
  initialState,
  on(RideSearchResultActions.addRideSearchResult, (state, action) =>
    adapter.addOne(action.rideSearchResult, state)
  ),
  on(RideSearchResultActions.upsertRideSearchResult, (state, action) =>
    adapter.upsertOne(action.rideSearchResult, state)
  ),
  on(RideSearchResultActions.addRideSearchResults, (state, action) =>
    adapter.addMany(action.rideSearchResults, state)
  ),
  on(RideSearchResultActions.upsertRideSearchResults, (state, action) =>
    adapter.upsertMany(action.rideSearchResults, state)
  ),
  on(RideSearchResultActions.updateRideSearchResult, (state, action) =>
    adapter.updateOne(action.rideSearchResult, state)
  ),
  on(RideSearchResultActions.updateRideSearchResults, (state, action) =>
    adapter.updateMany(action.rideSearchResults, state)
  ),
  on(RideSearchResultActions.deleteRideSearchResult, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(RideSearchResultActions.deleteRideSearchResults, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(RideSearchResultActions.loadRideSearchResults, (state, action) => ({
    ...state,
    searchSubject: action.subject,
    request: true,
    loaded: false,
  })),
  on(RideSearchResultActions.loadRideSearchResultsSuccess, (state, action) => ({
    ...adapter.setAll(action.results, state),
    loaded: true,
  })),
  on(RideSearchResultActions.loadRideSearchResultsFailed, (state, action) => ({
    ...state,
    error: action.error,
    loaded: true,
  })),
  on(RideSearchResultActions.clearRideSearchResults, (state) =>
    adapter.removeAll(state)
  ),
  on(RideSearchResultActions.searchRideOrders, (state) =>
    adapter.removeAll({ ...state, loaded: false })
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
