import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { AccountSelectors, UserData } from "../../../../account";
import { UserCompanySelectors } from "../../../../user-company/selectors";
import { Company } from "../../../models";
import { CompanyPermissionService } from "../../company-acl/services";
import { CompanyUser } from "../models";
import { CompanyUsersService } from "./company-users.service";

@Injectable({
  providedIn: "root",
})
export class CurrentCompanyUserService {
  user$: Observable<UserData>;
  company$: Observable<Company>;

  public companyUser$: Observable<CompanyUser>;
  constructor(
    private store: Store,
    private companyUserService: CompanyUsersService,
    private permissionService: CompanyPermissionService
  ) {
    this.user$ = combineLatest([
      this.store.select(AccountSelectors.selectLoaded),
      this.store.select(AccountSelectors.selectUser),
    ]).pipe(
      filter(([loaded, user]) => loaded),
      map(([_loaded, user]) => user)
    );
    this.company$ = this.store
      .select(UserCompanySelectors.selectCurrent)
      .pipe(filter((company) => !!company));

    this.companyUser$ = combineLatest([this.user$, this.company$]).pipe(
      filter(([user, company]) => !!user || !!company),
      switchMap(([user, company]) =>
        this.companyUserService.getByUserId(company.id, user.id)
      )
    );
  }

  hasPermission(permission: string) {
    return of(true);
  }

  hasAllPermissions(premissions: string[]) {
    return of(true);
  }
}
