import { Injectable } from "@angular/core";
import { Device } from "@ionic-native/device/ngx";
import { HttpClient } from "@angular/common/http";
import { ConnectableObservable, Observable, fromEvent } from "rxjs";
import { first, map, shareReplay } from "rxjs/operators";

export interface DeviceData {
  uuid: string;
  manufacturer: string;
  model: string;
  version: string;
  serial: string;
}

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  deviceReady$: Observable<Event>;
  constructor(private device: Device, private api: HttpClient) {
    this.deviceReady$ = fromEvent(document, "deviceready")
      .pipe(
        first(),
        shareReplay()
      );
  }

  getDeviceData(): Observable<DeviceData> {
    return this.deviceReady$.pipe(
      map(() => ({
        uuid: this.device.uuid,
        model: this.device.model,
        serial: this.device.serial,
        version: this.device.version,
        manufacturer: this.device.manufacturer,
      }))
    );
  }

  registerDevice(registration: string) {
    const data = {
      ...this.getDeviceData(),
      registrationToken: registration,
    };
    return this.api.post("/device/register", { device: data });
  }
}
