import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromRideSearchResult from "../ride-search-result.reducer";

export const selectRideSetupState = createFeatureSelector<fromRideSearchResult.State>(
  fromRideSearchResult.rideSearchResultsFeatureKey
);
export const loaded = createSelector(
  selectRideSetupState,
  (state) => state.loaded
);
export const selectScope = createSelector(
  selectRideSetupState,
  (state) => state.searchSubject
);
export const selectAll = createSelector(
  selectRideSetupState,
  fromRideSearchResult.selectAll
);

export const selectEntities = createSelector(
  selectRideSetupState,
  fromRideSearchResult.selectEntities
);

export const selectTotal = createSelector(
  selectRideSetupState,
  fromRideSearchResult.selectTotal
);

export const selectIds = createSelector(
  selectRideSetupState,
  fromRideSearchResult.selectTotal
);
