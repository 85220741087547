import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { fromCompanyUser } from "./reducers";
import { CompanyUserEffects } from "./effects/company-user.effects";
import { CompanyUsersService, CurrentCompanyUserService } from "./services";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCompanyUser.companyUsersFeatureKey,
      fromCompanyUser.reducer
    ),
    EffectsModule.forFeature([CompanyUserEffects]),
  ],
})
export class CompanyUserModule {
  public static forRoot(): ModuleWithProviders<CompanyUserModule> {
    return {
      ngModule: CompanyUserModule,
      providers: [CompanyUsersService, CurrentCompanyUserService],
    };
  }
}
