import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of } from "rxjs";
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { AlertService } from "src/app/alert/services/alert.service";
import { AccountSelectors, UserData } from "../../../../account";
import { CompanyUsersService } from "../../company-user";
import { CurrentCompanyUserService } from "../../company-user/services/current-company-user.service";
import { CompanyPermissionService } from "../services";

@Injectable({
  providedIn: "root",
})
export class CanEditUserGuard implements CanActivate {
  user$: Observable<UserData>;

  constructor(
    private store: Store,
    private permissionService: CompanyPermissionService,
    private companyUserService: CompanyUsersService,
    private router: Router,
    private currentCompanyUserService: CurrentCompanyUserService,
    private alertService: AlertService //NU E CORECT SA FOLOSIM ALERT SERVICE IN GUARDUL ASTA
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const companyUserId = next.paramMap.get("user");
    const companyId = next.paramMap.get("company");
    return combineLatest([
      this.currentCompanyUserService.companyUser$,
      this.companyUserService.get(companyId, companyUserId),
    ]).pipe(
      first(),
      map(([companyUser, targetCompanyUser]) => {
        if (companyUser.user_id === targetCompanyUser.user_id) {
          throw new Error("Nu poti edita roulul acetui utilizator.");
        }
        return [companyUser, targetCompanyUser];
      }),
      switchMap(([companyUser, targetCompanyUser]) => {
        return this.currentCompanyUserService.hasPermission(
          "edit_company_user"
        );
      }),
      catchError((error) => {
        this.alertService.presentAlert({ header: error.message }).toPromise();
        return of(this.router.parseUrl(`/c/${companyId}/users`));
      })
    );
  }
}
