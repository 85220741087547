import { createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { UserCompany } from "../models/user-company.model";
import { UserCompanyActions } from "../actions";

export const userCompaniesFeatureKey = "userCompanies";

export interface State extends EntityState<UserCompany> {
  // additional entities state properties
  loaded: boolean;
  currentCompany: string;
}

export const adapter: EntityAdapter<UserCompany> = createEntityAdapter<UserCompany>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  currentCompany: undefined,
});

export const reducer = createReducer(
  initialState,
  on(UserCompanyActions.currentCompanyChanged, (state, action) => ({
    ...state,
    currentCompany: action.id,
  })),
  on(UserCompanyActions.addUserCompany, (state, action) =>
    adapter.addOne(action.company, state)
  ),
  on(UserCompanyActions.upsertUserCompany, (state, action) =>
    adapter.upsertOne(action.company, state)
  ),
  on(UserCompanyActions.addUserCompanies, (state, action) =>
    adapter.addMany(action.companies, state)
  ),
  on(UserCompanyActions.upsertUserCompanies, (state, action) =>
    adapter.upsertMany(action.companies, state)
  ),
  on(UserCompanyActions.updateUserCompany, (state, action) =>
    adapter.updateOne(action.company, state)
  ),
  on(UserCompanyActions.updateUserCompanies, (state, action) =>
    adapter.updateMany(action.companies, state)
  ),
  on(UserCompanyActions.deleteUserCompany, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(UserCompanyActions.deleteUserCompanies, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(UserCompanyActions.loadUserCompaniesSuccess, (state, action) =>
    adapter.setAll(action.companies, { ...state, loaded: true })
  ),
  on(UserCompanyActions.clearUserCompanies, (state) =>
    adapter.removeAll(state)
  ),
  on(UserCompanyActions.reset, (_) => initialState)
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
