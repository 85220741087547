import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { filter, first, map, switchMap, tap } from "rxjs/operators";
import { ApiService } from "../../../../services";
import { RideWaypointType, SpaceTypes } from "../../models";
import { RideOrderData, RideRouteData } from "../../types";
import { DirectionsService } from "../../../google";
import { RideSearchResult } from "./ride-search-result.model";
import { RideSearchResultActions } from "./actions";
import { RideOrderResultSelectors } from "./selectors";
import {
  RideOrderSearchFilters,
  RideOrderSearchFiltersDto,
} from "./models/ride-order-filters.dto";
@Injectable({
  providedIn: "root",
})
export class RideSearchService {
  constructor(
    private api: ApiService,
    private store: Store,
    private directionsService: DirectionsService
  ) {}

  submitCurrentRide() {}

  getMatchingRides(
    companyId: string | number,
    rideId: string | number
  ): Observable<RideSearchResult[]> {
    return this.api
      .get(`companies/${companyId}/ride-orders/${rideId}/matches`)
      .pipe(map(({ results }) => results as RideSearchResult[]));
  }

  waitLoading() {
    return <T>(source: Observable<T>) =>
      source.pipe(
        switchMap((source) =>
          this.store.select(RideOrderResultSelectors.loaded).pipe(
            filter((loaded) => loaded),
            map(() => source)
          )
        )
      );
  }

  getResult(id: string | number) {
    return this.store.select(RideOrderResultSelectors.selectAll).pipe(
      this.waitLoading(),
      map((results) => {
        const result = results.find((res) => res.id == id);
        if (!result) {
          throw new Error("Result not in the list");
        }
        return result;
      })
    );
  }
  public mapFilters(
    filters: Partial<RideOrderSearchFilters>
  ): Partial<RideOrderSearchFiltersDto> {
    const mappedFilters: any = {
      ride_order_id: filters.ride_order_id,
      detour_range: filters.detour_range,
      type: filters.type,
      departure_date_from: filters.departure_date_from?.toISOString(),
      departure_date_to: filters.departure_date_to?.toISOString(),
      max_cargo_volume: filters.max_cargo_volume,
      max_cargo_weight: filters.max_cargo_weight,
      max_floor_length: filters.max_floor_length,
      min_cargo_volume: filters.min_cargo_volume,
      min_cargo_weight: filters.min_cargo_weight,
      min_floor_length: filters.min_floor_length,
    };
    if (filters.origin) {
      mappedFilters.origin = `${filters.origin?.geometry?.location?.lat()},${filters.origin?.geometry?.location?.lng()}`;
    }
    if (filters.destination) {
      mappedFilters.destination = `${filters.destination?.geometry?.location?.lat()},${filters.destination?.geometry?.location?.lng()}`;
    }
    Object.keys(mappedFilters).forEach((key) =>
      [undefined, null, ""].includes(mappedFilters[key])
        ? delete mappedFilters[key]
        : {}
    );
    return mappedFilters;
  }
  search(
    filters: Partial<RideOrderSearchFiltersDto>
  ): Observable<RideSearchResult[]> {
    return this.api
      .get(`ride-orders`, filters)
      .pipe(map(({ ride_orders }) => ride_orders as RideSearchResult[]));
  }
}
