import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { UserCompanyActions } from "../../../../user-company/actions";
import { CompanyUserActions } from "../actions";
import { CompanyUsersService } from "../services";

@Injectable()
export class CompanyUserEffects {
  changeCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserCompanyActions.currentCompanyChanged),
      map(({ id }) => CompanyUserActions.loadCompanyUsers({ company: id }))
    );
  });

  loadCompanyUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyUserActions.loadCompanyUsers),
      mergeMap(({ company }) =>
        this.companyUsersService.getAll(company).pipe(
          map((companyUsers) =>
            CompanyUserActions.upsertCompanyUsers({ companyUsers })
          ),
          catchError((error) => of(null))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private companyUsersService: CompanyUsersService
  ) {}
}
