import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ApiService } from "../../../services";

@Injectable({
  providedIn: "root",
})
export class CompanyIdentifierService {
  constructor(private api: ApiService) {}

  fetchCompanyByCui(cui: string) {
    return this.api
      .get(`identify-company`, { cui })
      .pipe(map((data) => data.company));
  }
}
