import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { CompanyUser } from "../models/company-user.model";

export const loadCompanyUsers = createAction(
  "[CompanyUser/API] Load CompanyUsers",
  props<{ company: string }>()
);

export const addCompanyUser = createAction(
  "[CompanyUser/API] Add CompanyUser",
  props<{ companyUser: CompanyUser }>()
);

export const upsertCompanyUser = createAction(
  "[CompanyUser/API] Upsert CompanyUser",
  props<{ companyUser: CompanyUser }>()
);

export const addCompanyUsers = createAction(
  "[CompanyUser/API] Add CompanyUsers",
  props<{ companyUsers: CompanyUser[] }>()
);

export const upsertCompanyUsers = createAction(
  "[CompanyUser/API] Upsert CompanyUsers",
  props<{ companyUsers: CompanyUser[] }>()
);

export const updateCompanyUser = createAction(
  "[CompanyUser/API] Update CompanyUser",
  props<{ companyUser: Update<CompanyUser> }>()
);

export const updateCompanyUsers = createAction(
  "[CompanyUser/API] Update CompanyUsers",
  props<{ companyUsers: Update<CompanyUser>[] }>()
);

export const deleteCompanyUser = createAction(
  "[CompanyUser/API] Delete CompanyUser",
  props<{ id: string }>()
);

export const deleteCompanyUsers = createAction(
  "[CompanyUser/API] Delete CompanyUsers",
  props<{ ids: string[] }>()
);

export const clearCompanyUsers = createAction(
  "[CompanyUser/API] Clear CompanyUsers"
);

export const setCurrentCompany = createAction(
  "[CompanyUser/API] Set Company User",
  props<{ id: string }>()
);
