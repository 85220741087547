import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { RideSearchResult } from "../ride-search-result.model";
import { RideOrder } from "../../../models";
import { RideOrderSearchFiltersDto } from "../models/ride-order-filters.dto";

export const searchRideOrders = createAction(
  "[RideSearchResult/API] Search Ride Order Results",
  props<{ filters: Partial<RideOrderSearchFiltersDto> }>()
);
export const loadRideSearchResults = createAction(
  "[RideSearchResult/API] Load RideSearchResults",
  props<{ subject: RideOrder }>()
);
export const loadRideSearchResultsSuccess = createAction(
  "[RideSearchResult/API] Load RideSearchResults Success",
  props<{ results: RideSearchResult[] }>()
);
export const loadRideSearchResultsFailed = createAction(
  "[RideSearchResult/API] Load RideSearchResults Failed",
  props<{ error: any }>()
);
export const addRideSearchResult = createAction(
  "[RideSearchResult/API] Add RideSearchResult",
  props<{ rideSearchResult: RideSearchResult }>()
);

export const upsertRideSearchResult = createAction(
  "[RideSearchResult/API] Upsert RideSearchResult",
  props<{ rideSearchResult: RideSearchResult }>()
);

export const addRideSearchResults = createAction(
  "[RideSearchResult/API] Add RideSearchResults",
  props<{ rideSearchResults: RideSearchResult[] }>()
);

export const upsertRideSearchResults = createAction(
  "[RideSearchResult/API] Upsert RideSearchResults",
  props<{ rideSearchResults: RideSearchResult[] }>()
);

export const updateRideSearchResult = createAction(
  "[RideSearchResult/API] Update RideSearchResult",
  props<{ rideSearchResult: Update<RideSearchResult> }>()
);

export const updateRideSearchResults = createAction(
  "[RideSearchResult/API] Update RideSearchResults",
  props<{ rideSearchResults: Update<RideSearchResult>[] }>()
);

export const deleteRideSearchResult = createAction(
  "[RideSearchResult/API] Delete RideSearchResult",
  props<{ id: string }>()
);

export const deleteRideSearchResults = createAction(
  "[RideSearchResult/API] Delete RideSearchResults",
  props<{ ids: string[] }>()
);

export const clearRideSearchResults = createAction(
  "[RideSearchResult/API] Clear RideSearchResults"
);
