// NGRX
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';

// tslint:disable-next-line:no-empty-interface
export interface AppState {}

export const reducers: ActionReducerMap<AppState> = {};

export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [] : [];
