import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { ApiService } from "../../../services";
import { Company, CompanyDto } from "../../company";
import { CompanyUserActions } from "../../company/@modules/company-user";
import { UserCompanyActions } from "../actions";
import { UserCompany } from "../models";
import { CreateCompanyDto } from "../models/create-company.dto";
import { UserCompanySelectors } from "../selectors";

@Injectable({
  providedIn: "root",
})
export class UserCompanyService {
  constructor(private api: ApiService, private store: Store) {}

  create(data: CreateCompanyDto): Observable<Company> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (
        ["logo", "vat_certificate", "trasport_license"].includes(key) &&
        data[key] instanceof Blob
      ) {
        formData.append(key, data[key], key);
        return;
      }
      formData.set(key, data[key]);
    });
    return this.api.post("companies", formData).pipe(
      map(({ company }) => company as UserCompany),
      tap((company) => {
        this.store.dispatch(UserCompanyActions.upsertUserCompany({ company }));
        this.store.dispatch(
          CompanyUserActions.upsertCompanyUsers({
            companyUsers: company.company_users,
          })
        );
      })
    );
  }

  update(data: CompanyDto): Observable<Company> {
    return this.api.put(`companies/${data.id}`, data).pipe(
      map(({ company }) => company as Company),
      tap((company) => {
        this.store.dispatch(UserCompanyActions.upsertUserCompany({ company }));
      })
    );
  }

  remove(id: string): Observable<boolean> {
    return this.api.delete(`companies/${id}`).pipe(
      map(() => true),
      tap((company) => {
        this.store.dispatch(UserCompanyActions.deleteUserCompany({ id }));
      })
    );
  }
  fetchCompany(id: string) {
    return this.api.get(`companies/${id}`).pipe(
      map(({ company }) => company as Company),
      tap((company) => {
        this.store.dispatch(UserCompanyActions.upsertUserCompany({ company }));
        this.store.dispatch(
          CompanyUserActions.upsertCompanyUsers({
            companyUsers: company.company_users,
          })
        );
      })
    );
  }
  checkCheckCompaniesLoaded() {
    return <T>(source: Observable<T>): Observable<T> => {
      return source.pipe(
        withLatestFrom(this.store.select(UserCompanySelectors.loaded)),
        tap(([data, loaded]) => {
          if (!loaded) {
            this.store.dispatch(UserCompanyActions.loadUserCompanies());
          }
        }),
        filter(([data, loaded]) => !!loaded),
        map(([data, loaded]) => data)
      );
    };
  }
  get(id: string): Observable<Company> {
    return this.store.select(UserCompanySelectors.selectById(id)).pipe(
      this.checkCheckCompaniesLoaded(),
      switchMap((company) => {
        if (!company) {
          return this.fetchCompany(id);
        }
        return of(company);
      })
    );
  }

  getAll(): Observable<UserCompany[]> {
    return this.api
      .get("companies")
      .pipe(map(({ companies }) => companies as UserCompany[]));
  }
}
