import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  RideOrdersService,
  RideRoutesService,
} from "./services";
import { EffectsModule } from "@ngrx/effects";
import { RideOrdersEffects } from "./effects/ride-orders.effects";
import { StoreModule } from "@ngrx/store";
import { fromRideOrders } from "./reducers";
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([RideOrdersEffects]),
    StoreModule.forFeature(
      fromRideOrders.rideOrdersFeatureKey,
      fromRideOrders.reducer
    ),
  ],
  providers: [RideOrdersService, RideRoutesService],
})
export class RideOrdersModule { }
