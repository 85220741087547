import { Injectable } from "@angular/core";
import { encode, decode } from "./flexible-polyline";

export interface PolylineDecodeResult {
  polyline: [number, number][];
}

@Injectable({
  providedIn: "root",
})
export class FlexiblePolylineService {
  constructor() {}

  encode(polyline: [number, number][]) {
    //todo
    return encode({ polyline });
  }

  decode(polyline: string): PolylineDecodeResult {
    return decode(polyline);
  }
}
