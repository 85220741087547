// NGRX
import { createSelector } from '@ngrx/store';
// Lodash
// Selectors

export const selectLocationState = (state) => state.location;

export const location = createSelector(
  selectLocationState,
  (locationState) => locationState.location
);
export const ready = createSelector(
  selectLocationState,
  (locationState) => locationState.ready
);
export const notReady = createSelector(
  selectLocationState,
  (locationState) => !locationState.ready
);
