import { createAction, props } from "@ngrx/store";
import { Update } from "@ngrx/entity";

import { Ride } from "../models/ride.model";

export const loadRides = createAction(
  "[Ride/API] Load Rides",
  props<{ company: number | string }>()
);
export const loadRidesSuccess = createAction(
  "[Ride/API] Load Rides Sucess",
  props<{ rides: Ride[] }>()
);
export const loadRidesFailed = createAction(
  "[Ride/API] Load Rides Failed",
  props<{ error: any }>()
);

export const addRide = createAction(
  "[Ride/API] Add Ride",
  props<{ ride: Ride }>()
);

export const upsertRide = createAction(
  "[Ride/API] Upsert Ride",
  props<{ ride: Ride }>()
);

export const addRides = createAction(
  "[Ride/API] Add Rides",
  props<{ rides: Ride[] }>()
);

export const upsertRides = createAction(
  "[Ride/API] Upsert Rides",
  props<{ rides: Ride[] }>()
);

export const updateRide = createAction(
  "[Ride/API] Update Ride",
  props<{ ride: Update<Ride> }>()
);

export const updateRides = createAction(
  "[Ride/API] Update Rides",
  props<{ rides: Update<Ride>[] }>()
);

export const deleteRide = createAction(
  "[Ride/API] Delete Ride",
  props<{ id: string }>()
);

export const deleteRides = createAction(
  "[Ride/API] Delete Rides",
  props<{ ids: string[] }>()
);

export const clearRides = createAction("[Ride/API] Clear Rides");
