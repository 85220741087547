import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ApiService } from "../../../services";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { filter, map, skipUntil, switchMap, tap } from "rxjs/operators";
import { TirupApiState } from "../../../reducers";
import { AccountActions } from "../actions";
import { UserData } from "../models/user.model";
import { AccountSelectors } from "../selectors";
import { selectUser } from "../selectors/account.selectors";
import { UserDataDto } from "../dto";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  [x: string]: any;
  data$ = combineLatest([
    this.store.select(AccountSelectors.selectUser),
    this.store.select(AccountSelectors.selectLoaded),
  ]).pipe(
    filter(([_user, loaded]) => loaded),
    map(([user, _loaded]) => user)
  );
  private loaded$: ReplaySubject<boolean>;
  constructor(private api: ApiService, private store: Store<TirupApiState>) {}

  fetchData() {
    return this.api.get("user/data").pipe(
      map(({ data }) => {
        return data as UserData;
      })
    );
  }

  getdata(): Observable<UserData> {
    return this.store
      .select(selectUser)
      .pipe(skipUntil(this.store.select(AccountSelectors.selectLoaded)));
  }

  setData(data: Partial<UserData>) {
    this.store.dispatch(AccountActions.updateAccountData({ user: data }));
  }

  updateData(input: Partial<UserDataDto>): Observable<UserData> {
    const formData = new FormData();
    Object.keys(input).forEach((key) => {
      if (input[key] instanceof Blob) {
        formData.append(key, input[key], key);
        return;
      }
      formData.set(key, input[key]);
    });
    return this.api.post("user/data", formData).pipe(
      map(({ data }) => data as UserData),
      tap((user) => {
        this.store.dispatch(AccountActions.updateAccountDataSuccess({ user }));
      })
    );
  }
  setUsername(username: string) {
    return this.api.post("user/username", { username }).pipe(
      map(({ data }) => {
        return data as UserData;
      }),
      tap((user) => {
        this.store.dispatch(AccountActions.updateAccountDataSuccess({ user }));
      })
    );
  }

  updateProfilePicture(file: Blob) {
    return this.updateData({ profile_picture: file });
  }
}
