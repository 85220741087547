import { ModuleWithProviders, NgModule } from "@angular/core";
import { ApiService } from "./services/api.service";
import { TirupApiConfig } from "./types/tirup-config.type";
import { HttpClientModule } from "@angular/common/http";
import { AuthenticationModule } from "./modules/authentication/authentication.module";
import { AccountModule } from "./modules/account/account.module";
import { DeviceModule } from "./modules/device";
import { CompanyModule } from "./modules/company/company.module";
import { RidesModule } from "./modules/ride/rides.module";
import { IonicStorageModule } from "@ionic/storage";
import { UsersModule } from "./modules/users/users.module";
import { GoogleModule } from "./modules/google";
import { UserCompanyModule } from "./modules/user-company";
import { VehicleModule } from "./modules/vehicle";
import { StockTypesModule } from "./modules/stock-type";

@NgModule({
  declarations: [],
  providers: [],
  imports: [
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: "__tirup",
      driverOrder: ["indexeddb", "sqlite", "websql"],
    }),
    AuthenticationModule,
    AccountModule,
    CompanyModule,
    DeviceModule,
    RidesModule,
    UsersModule,
    VehicleModule,
    StockTypesModule,
    GoogleModule,
    UserCompanyModule,
  ],
})
export class TirupApiModule {
  static forRoot(config: TirupApiConfig): ModuleWithProviders<TirupApiModule> {
    return {
      ngModule: TirupApiModule,
      providers: [{ provide: TirupApiConfig, useValue: config }, ApiService],
    };
  }
}
