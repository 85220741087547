import { Injectable } from "@angular/core";
import { ApiService } from "../../services";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { StockType } from "./stock-type.model";

@Injectable({
  providedIn: "root",
})
export class StockTypeService {
  constructor(private api: ApiService) { }

  getAll(): Observable<StockType[]> {
    return this.api
      .get("stock/types")
      .pipe(map(({ stock_types }) => stock_types as StockType[]));
  }
}
