import { RideOrderType } from "./ride-order-type";

export enum RideWaypointType {
  ORIGIN = "origin",
  WAYPOINT = "waypoint",
  DESTINATION = "destination",
}

export interface RideWaypoint {
  id: number;
  ride_order_id?: number;
  type: RideWaypointType;
  order_index: number;
  place_id: string;
  formatted_address: string;
  name: string;
  location_lat: number;
  location_lng: number;
}
