import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HereDirectionsInput } from "../interfaces";
import { FlexiblePolylineService } from "./flexible-polyline.service";

export interface RouteResultSummary {
  polyline: string;
  duration: number;
  distance: number;
}

@Injectable({
  providedIn: "root",
})
export class RoutingService {
  baseUrl = "https://router.hereapi.com/v8/routes";

  constructor(
    private http: HttpClient,
    private flexiblePolyline: FlexiblePolylineService
  ) {}

  public route(dto: HereDirectionsInput): Observable<any> {
    const data: any = {
      ...dto,
      origin: `${dto.origin?.lat},${dto.origin?.lng}`,
      destination: `${dto.destination?.lat},${dto.destination?.lng}`,
      apiKey: environment.here.javascript.secret,
      return: dto.return?.join(","),
    };
    if (dto.via) {
      data.via = dto.via.map((via) => `${via.lat},${via.lng}`);
    }
    return this.http.get(this.baseUrl, { params: data });
  }

  public routeSummary(dto: HereDirectionsInput) {
    dto = { ...dto, return: ["summary", "polyline", "travelSummary"] };
    return this.route(dto).pipe(
      map((result) => {
        let points: [number, number][] = [];
        const summary: RouteResultSummary = {
          distance: 0,
          polyline: "",
          duration: 0,
        };
        result.routes[0].sections.forEach((section) => {
          summary.distance += section.summary.length;
          summary.duration += section.summary.duration;
          const sectionPoints = this.flexiblePolyline.decode(section.polyline);
          console.log(sectionPoints);
          points = [...points, ...sectionPoints.polyline];
        });
        summary.polyline = this.flexiblePolyline.encode(points);
        return summary;
      })
    );
  }
}
