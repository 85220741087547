import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY, of } from "rxjs";
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
  timeout,
} from "rxjs/operators";
import {
  AccountActions,
  ActiveRideActions,
  ActiveRideSelectors,
  AuthActions,
  CompanyInviteActions,
  UserCompanyActions,
  NotificationType,
} from "tirup-api";
import { PushActions } from "./push";

@Injectable()
export class AppEffects {
  onAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authorized),
      mergeMap(() => [
        AccountActions.loadAccount(),
        UserCompanyActions.loadUserCompanies(),
        CompanyInviteActions.loadUserCompanyInvites(),
      ])
    );
  });

  openActiveRide$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ActiveRideActions.openRide),
        switchMap(() => this.store.select(ActiveRideSelectors.ride)),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        tap((ride) =>
          this.router.navigate(["c", ride.company_id, "active-ride"])
        )
      );
    },
    { dispatch: false }
  );
  openActiveRideOnAppStart$ = createEffect(() => {
    return this.actions$.pipe(
      timeout(2000),
      ofType(ActiveRideActions.set),
      map(({ ride }) => ActiveRideActions.openRide()),
      catchError((e) => EMPTY)
    );
  });
  pushReceived$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PushActions.pushNotificationReceived),
        concatMap(({ notification }) =>
          this.handleNotificationReceived(notification)
        )
      );
    },
    { dispatch: false }
  );

  handlePushTap$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PushActions.pushNotificationTapped),
        concatMap(({ notification }) =>
          this.handleNotificationTapped(notification)
        )
      );
    },
    { dispatch: false }
  );

  handleNotificationReceived(notification: any) {
    switch (notification.type) {
    }
    return EMPTY;
  }

  handleNotificationTapped(data: any) {
    let notification = Object.assign({}, data, data.data);
    switch (notification.type) {
      case NotificationType.NEW_RIDE_ORDER_CALL:
        this.router.navigate([
          "c",
          notification.company_id,
          "ride-orders",
          "ride-order",
          notification.ride_order_id,
        ]);
        break;
      default:
        this.router.navigate(["notifications", notification]);
        break;
    }
    return EMPTY;
  }
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store
  ) {}
}
