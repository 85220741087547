import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonMenu, MenuController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import {
  AccountSelectors,
  AuthActions,
  Company,
  UserCompanyActions,
  UserCompanySelectors,
  UserData,
  UserService,
} from "tirup-api";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, OnDestroy {
  currentCompany$: Observable<Company>;

  user$: Observable<UserData>;
  unsubscribe$: Subject<void>;

  username$: Observable<string>;

  @ViewChild("menu")
  menu: IonMenu;
  constructor(private store: Store, private userService: UserService) {}

  ngOnInit(): void {
    this.unsubscribe$ = new Subject();
    this.currentCompany$ = this.store.select(
      UserCompanySelectors.selectCurrent
    );
    this.user$ = combineLatest([
      this.store.select(AccountSelectors.selectUser),
      this.store.select(AccountSelectors.selectLoaded),
    ]).pipe(
      filter(([_user, loaded]) => loaded),
      map(([user, _loaded]) => user),
      takeUntil(this.unsubscribe$)
    );
    this.username$ = this.user$.pipe(
      map((data) => this.userService.getName(data))
    );
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeMenu() {
    this.menu.close();
  }
  logout() {
    this.store.dispatch(AuthActions.unauthorized());
    this.closeMenu();
  }
}
